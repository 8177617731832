import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    Checkbox,
    DialogContent, Divider, FormControlLabel, FormGroup, Grid, Link, TextField
} from "@material-ui/core";
import {withRouter} from "react-router";
import {
    closeModal,
    conversationsWithFlag,
    deleteTopic, deleteUser,
    fetchTopics,
    flag,
    showModal,
    usersWithFlag
} from "../../actions";
import Typography from "@material-ui/core/Typography";
import LogoInit from "../Logo/LogoInit";
import {blue} from "@material-ui/core/colors";

const styles = theme => ({
    buttonSubmit: {
        margin: '0.313rem',
        padding: '0.313rem 1.563rem',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.link.main,
        '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.link.main,
        }
    },
    buttonCancel: {
        margin: '0.313rem',
        padding: '0.313rem 1.563rem',
    }
});


class ModalDeleteTopic extends Component {

    state = {
        modalTopic: this.props.modal.data.content,
        modalIsOpen: false,
    }

    componentDidMount() {
        // console.log(this.props.modal.data.content.topicId);
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-delete-topic')
    };

    deleteTopic = (type, data) => {

        if (this.state.modalTopic.deleteObject && this.state.modalTopic.deleteObject === 'user'){
            // this.state.modalTopic.topicId is the id of the user
            this.props.deleteUser(this.state.modalTopic.conferenceUUID, this.state.modalTopic.topicId)
                .then(() => {
                    this.props.usersWithFlag(this.state.modalTopic.conferenceUUID)
                    this.props.showModal(type, data)
                })
        } else {

            this.props.deleteTopic(this.state.modalTopic.topicId)
                .then(() => {
                    this.props.fetchTopics(this.state.modalTopic.conferenceUUID)
                    this.props.conversationsWithFlag(this.state.modalTopic.conferenceUUID)
                    this.props.usersWithFlag(this.state.modalTopic.conferenceUUID)

                    this.props.showModal(type, data)
                })
        }

    };

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__topic-feedback'>

                    <div style={{textAlign: 'center', margin: '20px 20px 30px'}}>
                        {/*SPARCMORE LOGO*/}
                        <LogoInit variant={'h4'}/>
                        {/* !SPARCMORE LOGO*/}
                    </div>

                    <Typography variant='h6' style={{textAlign: 'center'}}>
                        {`Are you sure you want to delete`}
                    </Typography>
                    <Typography variant='h5' style={{textAlign: 'center', marginTop: '15px'}}>
                        "{this.state.modalTopic.topicTitle}" ?
                    </Typography>
                    <br/>
                    <Divider/>
                    <br/>

                    <Typography variant='h6' style={{textAlign: 'center'}}>
                        This can not be undone.
                    </Typography>

                    <div className='modal__topic-feedback__actions'>
                        <Button
                            variant="contained"
                            className={classes.buttonSubmit}
                            onClick={() => {
                                this.deleteTopic('modal-feedback-response', this.state.modalTopic.deleteObject);
                            }
                            }
                        >
                            Delete
                        </Button>
                        <Button
                            variant="contained"
                            color='primary'
                            className={classes.buttonCancel}
                            onClick={this.closeModal}>
                            Cancel
                        </Button>
                    </div>

                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => (
    {
        closeModal: data => dispatch(closeModal(data)),
        showModal: (type, data) => dispatch(showModal(type, data)),
        deleteTopic: data => dispatch(deleteTopic(data)),
        deleteUser: (confUUIdD, userId) => dispatch(deleteUser(confUUIdD, userId)),
        fetchTopics: (uuid) => dispatch(fetchTopics(uuid)),
        conversationsWithFlag: (conferenceUuid) => dispatch(conversationsWithFlag(conferenceUuid)),
        usersWithFlag: (conferenceUuid) => dispatch(usersWithFlag(conferenceUuid)),
    }
)

export default withRouter(withStyles(styles,
    {
        withTheme: true
    }
)(connect(mapStateToProps, mapDispatchToProps)(ModalDeleteTopic)));
