import {
    UPDATE_PROFILE,
    UPDATE_PROFILE_ERROR
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PROFILE:
            return {...state, data: action.payload}
        case UPDATE_PROFILE_ERROR:
            return {...state, data: action.payload}
        default:
            return state;
    }
}
