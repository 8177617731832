import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal} from "../../actions";

const styles = theme => ({});

class ModalContent extends Component {

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-not-found')
    };

    render() {

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">
                    404
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Somethings went wrong
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeModal} color="primary">
                        close
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => ({

    closeModal: data => dispatch(closeModal(data))
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ModalContent)));
