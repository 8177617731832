import {
    FETCH_UPCOMING_MEETINGS
} from '../actions/types'


export default (state = {}, action) => {

    switch (action.type) {
        case FETCH_UPCOMING_MEETINGS:
            return {...state, data: action.payload.data}
        default:
            return state;
    }
}
