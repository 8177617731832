import {
    USER_BLOCK,
    USER_BLOCK_ERROR
} from '../actions/types'
import {topics} from "./TopicsReducer";


let INITIAL_STATE = {
    user: null,
    err: null
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_BLOCK:
            let allConversationsArr = topics(action.payload.data, 'start_at', 'end_at')
            return {...state, conversations: allConversationsArr}
        case USER_BLOCK_ERROR:
            return {...state, err: action.payload}
        default:
            return state;
    }
}
