import React, {Component} from "react";
import 'fontsource-roboto';
import {fetchMyTopics} from "../../actions";
import {connect} from 'react-redux'
import LogoInit from "../Logo/LogoInit";
import Typography from "@material-ui/core/Typography";
import {Grid, Link} from "@material-ui/core";

class MyConversationsPage extends Component {

    state = {}


    componentDidMount() {
    }

    render() {
        return (
            <Grid container justify={"center"}>
                <Grid item md={7}>
                    <div className='intro_content__wrapper'>
                        <div className='terms__logo-wrap'>
                            <LogoInit variant={'h2'}/>
                        </div>
                        <div className="separator">
                            <Typography className='welcome-text' gutterBottom>
                                C2C Labs, LLC, is owner and operator of SPARCmore.com (collectively “C2C Labs”, “We”, or
                                “Us”). These C2C Labs Website Terms of Use ("Terms") govern all SPARCmore.com web pages
                                ("Site").
                                By accessing, visiting, or otherwise using the Site, you agree to be bound by the Terms
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                Your Use of Our Site
                            </Typography>

                            <Typography className='welcome-text' gutterBottom>
                                When using our Site, you must always comply with C2C Lab's Acceptable Use Policy. For
                                the
                                purposes of these Terms, "Services" means Site and "You" means you.
                                <br/>
                                <br/>
                                You must also comply with all applicable laws, including federal, state, and local laws,
                                the
                                laws of your jurisdiction, and laws regarding the transmission of technical data.
                                <br/>
                                <br/>
                                C2C Labs' Privacy Policy describes how we will protect your privacy and handle your
                                personal
                                information when using our Site. By using the Site, you agree that C2C Labs can use such
                                information in accordance with this policy.
                                <br/>
                                <br/>
                                Do not use the Site to advertise, promote, endorse, or market, directly or indirectly,
                                any
                                third party commercial products, services, solutions, or other technologies.
                                <br/>
                                <br/>
                                In order to access certain services or features of our Site, you may be required to
                                create
                                an account or profile. We may also offer access to certain services and features of the
                                Site
                                to you for a fee. In those situations, or when using other C2C Labs products and
                                services as
                                part of the Site, additional C2C Labs terms or requirements may apply and those
                                additional
                                terms become part of your agreement with us. In the event of any conflict between such
                                additional terms and these Terms, the additional terms control.
                                <br/>
                                <br/>
                                If you violate these Terms, your access to the Site may be terminated immediately and
                                without notice.
                                <br/>
                                <br/>
                                <strong>
                                    Your Account
                                </strong>
                                <br/>
                                <br/>
                                If any portion of the Site requires you to open an account or create a user profile, the
                                information you provide must be truthful and accurate. You agree not to use the account,
                                username, or password of another user at any time or to disclose your password to any
                                third party. You must notify C2C Labs immediately of any unauthorized use of your
                                account or of any breach in security.
                                <br/>
                                <br/>
                                We may, in our sole discretion, (i) reject or remove anything you post or share via
                                audio and/or video, (ii) restrict, suspend, or terminate your access to any or all of
                                the Site, or (iii) cease to provide and maintain the Site, at any time, for any or no
                                reason, with or without prior notice, and without liability. Upon doing so, we may
                                retain or delete any information or content that you provided.
                                <br/>
                                <br/>
                                <strong>
                                    Attendee Account
                                </strong>
                                <br/>
                                <br/>
                                An Attendee is a person that accesses an event or session on the Site to participate in
                                active discussions with other Attendees. You may create your own Attendee account. As an
                                Attendee, you agree that C2C Labs has permission to gather information on your
                                interactions with the Site per
                                <Link href={`${process.env.REACT_APP_URL}/privacy-policy`} target='_blank'
                                   className='links-primary' color={"secondary"}> C2C Labs’ Privacy Policy</Link>.
                                <br/>
                                <br/>
                                <strong>
                                    Organizer Account
                                </strong>
                                <br/>
                                <br/>
                                You may need an Organizer account in order to use some of the Site’s services. You may
                                create your own Organizer account, or an account may be assigned to you by an
                                administrator,
                                such as your employer, conference or event organizer, or community manager. If you are
                                using
                                an account assigned to you by an administrator, different or additional terms may apply
                                and
                                your administrator may be able to access or disable your account.
                                <br/>
                                <br/>
                                You are responsible for maintaining the security of your account. You are fully
                                responsible
                                for all activities that occur under the account and any other actions taken in
                                connection
                                with the information you provide.
                                <br/>
                                <br/>
                                You must not describe or assign keywords to your events/sessions/activities in a
                                misleading
                                or unlawful manner, including in a manner intended to trade on the name or reputation of
                                others. C2C Labs may change or remove any description or keyword that it considers
                                inappropriate or unlawful, or otherwise likely to cause C2C Labs liability.
                                <br/>
                                <br/>
                                You must immediately notify C2C Labs of any unauthorized uses of your account or any
                                other
                                breaches of security. C2C Labs is not liable for your acts or omissions, including
                                damages
                                incurred as a result of such acts or omissions
                                <br/>
                                <br/>
                                <strong>Our Site Content</strong>
                                <br/>
                                <br/>
                                Our Site contains content owned, operated, licensed, and/or controlled by C2C Labs that
                                is
                                protected by copyright, trademark, trade secret, or other proprietary rights ("Site
                                Content"). C2C Labs or its licensors retain all rights in such Site Content. C2C Labs
                                grants
                                you a limited, revocable, non sublicensable right to view the Site Content solely for
                                your
                                internal use of the Site. Additionally, certain Site Content may include, but is not
                                limited
                                to, icons, photographs, white papers, product documentation, technology overviews, and
                                implementation guides.
                                <br/>
                                <br/>
                                The trademarks, logos, and service marks ("Marks") displayed on our Site are the
                                property of
                                C2C Labs or other third parties.
                                <strong>
                                    Your Content
                                </strong>
                                <br/>
                                <br/>

                                <br/>
                                <br/>
                                We do not claim any ownership rights in any text, files, images, photos, video, sounds,
                                software, works of authorship, or other materials that you upload or otherwise provide
                                to
                                our Site (collectively, "User Content"). However, you grant to C2C Labs a worldwide,
                                royalty-free, sublicensable, perpetual, irrevocable license to use, modify, publicly
                                perform, publicly display, reproduce, and distribute your User Content for the limited
                                purpose of providing the Site and all related services. You also agree that any other
                                user
                                of this Site may access, view, store, and reproduce your User Content for such user's
                                personal use or otherwise in connection with use of the Site.
                                <br/>
                                <br/>
                                You cannot post, modify, distribute, or reproduce in any way copyrighted or other
                                proprietary materials without obtaining the prior written consent of the copyright owner
                                of
                                such materials. We may terminate an account and/or deny access to any user who is
                                alleged to
                                have infringed the copyright or proprietary rights of another.
                                <br/>
                                <br/>
                                All User Content is considered nonconfidential and public. Please do not submit any
                                confidential or private information.
                                <br/>
                                <br/>
                                If you provide us with any feedback or ideas, you authorize C2C Labs to use your
                                feedback
                                for any purpose, without any restriction or limitation.
                                <br/>
                                <br/>
                                It is C2C Labs’ policy to (1) block access to or remove any content (including, without
                                limitation, text, graphics, and photos) that it believes in good faith to be copyrighted
                                material that has been illegally copied and distributed by any of our advertisers,
                                affiliates, content providers, members or users; and (2) remove and discontinue service
                                to
                                repeat offenders.
                                <br/>
                                <br/>
                                If you believe that content available by means of SPARCmore.com (as defined in our Terms
                                of
                                Service) infringes one or more of your copyrights, please notify us by providing a
                                written
                                notice (“Infringement Notice”) containing the information described below to the
                                designated
                                agent listed below. If C2C Labs takes action in response to an Infringement Notice, it
                                will
                                make a good faith attempt to contact the party that made such content available by means
                                of
                                the most recent email address, if any, provided by such party to C2C Labs.
                                <br/>
                                <br/>
                                Your Infringement Notice may be forwarded to the party that made the content available
                                or to
                                third parties.
                                <br/>
                                <br/>
                                Please be advised that you will be liable for damages (including costs and attorneys’
                                fees)
                                if you materially misrepresent that a product or activity is infringing your copyrights.
                                Thus, if you are not sure content located on or linked-to by SPARCmore.com infringes
                                your
                                copyright, you should consider first contacting an attorney.
                                <br/>
                                <br/>
                                Please follow these steps to file a notice:
                                <ul>
                                    <li>
                                        Verify that the content in question is hosted by SPARCmore.
                                    </li>
                                    <li>
                                        We only host pages that have "SPARCmore.com” in their URL. Contact the listing
                                        party
                                        directly. Go to the listing post in question and send an email with your
                                        complaint
                                        to see if the matter can be resolved directly between you and thelisting party.
                                        If
                                        the issue cannot be resolved directly with the listing party, send your
                                        complaint to
                                        support@sparcmore.com.
                                    </li>
                                </ul>
                                <br/>
                                <br/>
                                You must include the following:
                                <ul>
                                    <li>
                                        A physical or electronic signature of the copyright owner or a person authorized
                                        to
                                        act on their behalf;
                                    </li>
                                    <li>
                                        An identification of the copyright claimed to have been infringed;
                                    </li>
                                    <li>
                                        A description of the nature and exact location of the content that you claim to
                                        infringe your copyright, in sufficient detail to permit C2C Labs to find and
                                        positively identify that content; for example we require a link to the event or
                                        session that contains the content and a description of which specific portion of
                                        the
                                        event or session – an image, a link, the text, etc – your complaint refers to;
                                    </li>
                                    <li>
                                        Your name, address, telephone number and email address; and a statement by you:
                                        (a)
                                        that you believe in good faith that the use of the content that you claim to
                                        infringe your copyright is not authorized by law, or by the copyright owner or
                                        such
                                        owner’s agent; (b) that all of the information contained in your Infringement
                                        Notice
                                        is accurate, and (c) under penalty of perjury, that you are either the copyright
                                        owner or a person authorized to act on their behalf.
                                    </li>
                                </ul>
                                <br/>
                                <br/>
                                If the disputed materials were posted by a third party identifiable through reasonable
                                efforts, we will try to provide notice to the third party of the charge. If the third
                                party
                                responds, we will provide you with a copy so that you may take any other steps you may
                                consider appropriate.
                                <br/>
                                <br/>
                                <strong>
                                    Disclaimers and Limitations of Liability
                                </strong>
                                <br/>
                                <br/>
                                Except as specifically set forth above, nothing in these Terms confers, by implication,
                                estoppel, or otherwise, any license or right under any trade secret, patent, trademark,
                                copyright, or other intellectual property right of C2C Labs or any third party. All
                                rights
                                not expressly licensed are reserved.
                                <br/>
                                <br/>
                                Although we have attempted to provide accurate information on our Site, we assume no
                                responsibility for the accuracy of the information.
                                <br/>
                                <br/>
                                We do not endorse or assume responsibility for user conduct or what users submit to our
                                Site. Nothing in these Terms requires us to monitor the Site or to modify or remove any
                                materials or information.
                                <br/>
                                <br/>
                                Our Site contains links to third-party sites. We provide these links merely as a
                                convenience
                                and the inclusion of such links does not imply any endorsement of their content. Access
                                to
                                any other site linked to this Site is at your own risk and we are not responsible for
                                the
                                accuracy or reliability of any information, data, opinions, advice, or statements made
                                on
                                such sites.
                                <br/>
                                <br/>
                                You are solely responsible for maintaining the confidentiality of your account,
                                registration, and password information. C2C Labs will not be liable for any harm caused
                                by,
                                or related to, the theft or misappropriation of your user name or password, disclosure
                                of
                                your user name or password, or your authorization of anyone else to use your user name
                                or
                                password.
                                <br/>
                                <br/>
                                We may change the programs or products mentioned at any time without notice. Mention of
                                non-C2C Labs products or services is for informational purposes only and constitutes
                                neither
                                an endorsement nor a recommendation. Because international information is provided on
                                our
                                Site, not all products or programs mentioned will be available in your country.
                                <br/>
                                <br/>
                                The Site and all information on the Site are subject to applicable federal, state, and
                                local
                                laws, and U.S. export control laws, and may also be subject to the laws of the country
                                where
                                you reside. We reserve the right to investigate and take appropriate action against
                                anyone
                                who, in C2C Labs's sole discretion, is suspected of violating any applicable law,
                                including,
                                without limitation, reporting you to law enforcement authorities.
                                <br/>
                                <br/>
                                THIS SITE IS PROVIDED "AS IS" WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, EITHER
                                EXPRESSED OR IMPLIED. TO THE EXTENT PERMITTED BY LAW, C2C LABS DISCLAIMS ALL WARRANTIES,
                                EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THOSE OF MERCHANTABILITY, FITNESS
                                FOR A
                                PARTICULAR PURPOSE, AND NONINFRINGEMENT, OR THAT ARISE FROM A COURSE OF DEALING, USAGE,
                                OR
                                TRADE PRACTICE.
                                <br/>
                                <br/>
                                C2C LABS SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL
                                DAMAGES
                                INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR REVENUES, COSTS OF REPLACEMENT GOODS, OR
                                LOSS
                                OR DAMAGE TO DATA ARISING OUT OF THE USE OR INABILITY TO USE THIS SITE OR ANY RELATED
                                SERVICES OR CONTENT, OR DAMAGES RESULTING FROM USE OF OR RELIANCE ON THE INFORMATION
                                PRESENT, EVEN IF C2C LABS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                                <br/>
                                <br/>
                                C2C LABS DOES NOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE
                                SITE
                                OR ANY RELATED SERVICES OR CONTENT.
                                <br/>
                                <br/>
                                ALL USE OF THIS SITE IS AT YOUR OWN RISK.
                                <br/>
                                <br/>
                                <strong>
                                    About Our Terms
                                </strong>

                                <br/>
                                <br/>
                                We reserve the right to change these Terms from time to time at our sole discretion.
                                Please
                                check this page periodically for any updates.
                                <br/>
                                <br/>
                                These Terms are governed by the laws of the State of California, U.S.A., excluding any
                                conflict of laws rules or principles.
                                <br/>
                                <br/>
                                If there is any dispute relating to the Site or these Terms, you and C2C Labs agree to
                                exclusive personal jurisdiction and venue in the state and federal courts of San
                                Francisco
                                County, State of California, U.S.A.
                                <br/>
                                <br/>
                                If any provision of these Terms is found to be invalid or unenforceable, that provision
                                will
                                be struck and the remaining provisions will remain in full effect.
                                <br/>
                                <br/>
                                If you or others violate these Terms and we take no immediate action, this in no way
                                limits
                                or waives our rights, such as our right to take action in the future or in similar
                                situations.
                                <br/>
                                <br/>
                                These terms were last updated on November 2, 2020 and are effective immediately.
                                <br/>
                                <br/>
                                Thank You.


                            </Typography>

                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }

}

const mapStateToProps = (state) => {
    return {};
}

export default connect(
    mapStateToProps,
    {}
)(MyConversationsPage);
