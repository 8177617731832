import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Typography from "@material-ui/core/Typography";
import logoImage from '../../assets/images/SPARCmore_logo.png';

const styles = theme => ({
    
});


class LogoInit extends Component {

    state = {
       // variant: this.props.variant
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>

                    {/*SPARCMORE LOGO*/}
                    <Typography className={`header__logo__wrap`} variant={this.props.variant} noWrap>
                        <span className={`header__logo-img__wrap ${this.props.variant}`}>
                            <img src={logoImage} alt=""/>
                        </span>
                        <span className='header__logo-text__wrap'>
                            SPARCmore
                        </span>
                    </Typography>
                    {/* !SPARCMORE LOGO*/}
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(LogoInit)));
