import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Box, Grid, List, ListItem, Paper, InputBase, Divider, Card } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import ForumIcon from "@material-ui/icons/Forum";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';

class BrandingHeader extends Component {
    render() {
        let styles = {};
        if (this.props.data.header_type === 'image') {
            styles = {
                background: {
                    backgroundImage: 'url(' + this.props.data.branding_header_image + ')'
                },
                textColor: {
                    color: this.props.data.branding_header_text_color
                }
            }
        } else if (this.props.data.header_type === 'color') {
            styles = {
                background: {
                    backgroundColor: this.props.data.branding_header_color
                },
                textColor: {
                    color: this.props.data.branding_header_text_color
                }
            }
        }
        return (
            <Card>
                <Box px={1} className="branding__preview__top__header" style={styles.background}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ height: 40 }}>
                        <Grid item xs={3}>
                            <a href={this.props.data.branding_logo_url} target={this.props.data.branding_logo_url ? '_blank' : '_self'}>
                                <img src={this.props.data.branding_logo_image} style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: 80, maxHeight: 30 }} alt="" />
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.text &&
                                <Box textAlign="center" style={styles.textColor}>{this.props.conference.data.name}</Box>
                            }
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box className="branding__preview__top__header_nav header-wrap">
                    <div className='header__menu-logo__wrap'>
                        <List className='d-flex align-center justify-flex-start'>
                            <ListItem>
                                <ScreenShareIcon />
                                <Typography style={{ marginLeft: "10px" }}>Sessions</Typography>
                            </ListItem>
                            <ListItem className='Mui-selected'>
                                <ForumIcon />
                            </ListItem>
                            <ListItem>
                                <SubscriptionsIcon />
                            </ListItem>
                            <ListItem>
                                <GroupAddIcon />
                            </ListItem>
                        </List>
                    </div>
                    <Paper className="d-flex align-center justify-center">
                        <InputBase placeholder="Search..." style={{ height: 25, fontSize: 12, padding: 5 }} disabled />
                        <SearchIcon style={{ fontSize: 22, marginRight: 5 }} />
                    </Paper>
                    <div className='header__menu-logo__wrap'>
                        <List className='d-flex align-center justify-flex-start'>
                            <ListItem>
                                <AccountCircle />
                            </ListItem>
                        </List>
                    </div>
                </Box>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        conference: state.conferences
    };
}
const mapDispatchToProps = dispatch => ({})

export default withRouter((connect(mapStateToProps, mapDispatchToProps)(BrandingHeader)));
