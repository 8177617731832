import React, {Component} from "react";
import 'fontsource-roboto';
import ScrollableTabs from "../scrollableTabs/ScrollableTabs";
import TopicsSection from "../topicsSection/TopicsSection";
import {connect} from 'react-redux'
import {
    Typography
} from "@material-ui/core";
import TextFieldCreateTopic from "../textFieldCreateTopic/ТextFieldCreateTopic";
import {withSanctum} from "react-sanctum";
import {withRouter} from "react-router";
import {fetchConferences} from "../../actions";
import NotFound from "./NotFound";

class HomePage extends Component {

    state = {
        liveTopics: null,
        upcomingTopics: null
    }

    fetchTopics = () => {
        if (this.props?.liveTopics && this.props.liveTopics !== this.state.liveTopics) {
            // console.log('liveTopics');
            this.setState({liveTopics: this.props.liveTopics})

        }

        if (this.props?.upcomingTopics && this.props.upcomingTopics !== this.state.upcomingTopics) {
            // console.log('upcomingTopics');
            this.setState({upcomingTopics: this.props.upcomingTopics})
        }
    }

    componentDidMount() {
        this.fetchTopics()
    }

    componentDidUpdate() {
        this.fetchTopics()
    }

    renderContent = () => {
        return (
            <div>
                <ScrollableTabs/>
                <div className='mt-24'>
                    <TextFieldCreateTopic/>
                </div>
                <div className='mt-24'>
                    {
                        this.state.liveTopics
                            ?
                            <TopicsSection topicsSection={this.state.liveTopics}/>
                            :
                            <Typography>
                                Loading ...
                            </Typography>
                    }
                    {

                        this.state.upcomingTopics
                            ?

                            <TopicsSection topicsSection={this.state.upcomingTopics}/>
                            :
                            <Typography>
                                Loading ...
                            </Typography>
                    }
                </div>
            </div>
        );
    }

    render() {
        return this.renderContent()
    }

}

const mapStateToProps = (state) => {
    return {
        topics: state.topics,
        liveTopics: state.topics.liveTopics,
        upcomingTopics: state.topics.upcomingTopics,
        openLiveTopics: state.topics.openLiveTopics,
        conferences: state.conferences
    };
}

const mapDispatchToProps = dispatch => ({
    fetchConferences: (uuid) => dispatch(fetchConferences(uuid))
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(withSanctum(withRouter(HomePage)));
