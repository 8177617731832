import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Tabs, Tab, Box, Typography, Grid, List, ListItem, Paper } from "@material-ui/core";

const styles = theme => ({
    root: {
        maxWidth: '100%',
        marginBottom: 5,
        backgroundColor: theme.palette.primary.main
    }
});

class BrandingContentTwo extends Component {
    render() {
        const { classes } = this.props;

        let styles = {};
        if (this.props.data.background_type === 'image') {
            styles = {
                background: {
                    backgroundImage: 'url(' + this.props.data.branding_background_image + ')',
                    height: 'calc(100% - 80px)'
                }
            }
        } else if (this.props.data.background_type === 'color') {
            styles = {
                background: {
                    backgroundColor: this.props.data.branding_background_color,
                    height: 'calc(100% - 80px)'
                }
            }
        }
        return (
            <div className="branding__preview__background" style={styles.background}>
                <Box p={1}>
                    Conversation Topic
                </Box>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(BrandingContentTwo);

