import {
    NOTIFY_SUBSCRIBERS, NOTIFY_SUBSCRIBERS_ERROR
} from '../actions/types';


export default (state = {}, action) => {
    switch (action.type) {
        case NOTIFY_SUBSCRIBERS:
            return {...state, data: action.payload}
        case NOTIFY_SUBSCRIBERS_ERROR:
            return {...state, error: action.payload}
        default:
            return state;
    }
}
