import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import {Card, CardActions, CardContent, CardHeader, Collapse} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import LPGraphic from '../../assets/images/lp-graphic.jpeg'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";

const styles = theme => ({
    root: {
        maxWidth: '100%',
        backgroundColor: theme.palette.primary.main
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
});


class WelcomeSection extends Component {

    state = {
        expanded: false
    }

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
    };

    render() {
        const {classes} = this.props;

        return (
            <div className='welcome-section__wrap'>
                <Card className={classes.root}>
                    <div
                        className='card-header__wrap'
                        onClick={this.handleExpandClick}
                    >
                        <CardHeader
                            component={Typography}
                            variant='h3'
                            title='SPARCmore Meaningful Conversations'
                            color='secondary'
                        />

                        <CardActions disableSpacing>

                            <IconButton
                                color='secondary'
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                })}
                                aria-expanded={this.state.expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                        </CardActions>
                    </div>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <div className='welcome-section__content'>
                                <div className='welcome-section__content__text'>
                                    <Typography paragraph>SPARCmore helps like-minded business professionals discover
                                        and virtually meet for meaningful conversations.
                                        No blind introductions, no long list of names, no time-consuming questionnaires,
                                        no missed chat threads.</Typography>
                                    <Typography paragraph>
                                        Ignite relationships and strengthen community in 3 easy steps:
                                    </Typography>
                                    <ol className='list__decimal'>
                                        <li>
                                            <Typography paragraph>
                                                <span>CREATE a conversation room by sharing something you’ll like to discuss with a group.</span>
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography paragraph>
                                                <span>SUBSCRIBE to conversations to be alerted about when they are taking place and any additional info.</span>
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography paragraph>
                                                <span>JOIN LIVE virtual conversations to Share, Participate, Assist, Reimagine, and Collaborate on shared interests.</span>
                                            </Typography>
                                        </li>
                                    </ol>
                                    <Typography paragraph>
                                        We believe meaningful conversations ignite relationships and strengthen
                                        community.
                                        CREATE, SUBSCRIBE, or JOIN a conversation today!
                                    </Typography>
                                </div>
                                <div className='welcome-section__content__img'>
                                    <img className='img-fluid' src={LPGraphic} alt=""/>
                                </div>
                            </div>
                        </CardContent>
                    </Collapse>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles, {withTheme: true})(WelcomeSection);
