import React, {useEffect, useState} from 'react';
import {Divider, ListItem, ListItemIcon, ListItemText, makeStyles, Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import {fetchConferences, fetchTopics} from "../../actions";
import {connect} from "react-redux";
import GroupIcon from '@material-ui/icons/Group';


const useStyles = makeStyles((theme) => ({
    liveSessionsTitle: {
        padding: '0 1rem',
        borderBottom: "1px solid",
        borderColor: theme.palette.secondary.main
    },
    listText: {
        '& > span': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    },
    activeSession: {
        color: theme.palette.secondary.main,
        '& svg': {
            fill: theme.palette.secondary.main
        }
    }
}))


function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function LiveSessions(props) {

    const [activeConference, setActiveConference] = useState(props.conferenceUUId)
    const [activeEvent, setActiveEvent] = useState(props.activeTopicUUId)
    const [liveTopics, setLiveTopics] = useState([])
    const [meeting, setMeeting] = useState({
        host: null,
        id: null,
        slug: "",
        title: "",
        user_meetings: [],
        uuid: null,
    })


    useEffect(() => {
        props.fetchConferences(activeConference)
            .then(()=>{
                props.fetchTopics(activeConference)
            })

    }, [activeConference, props.meeting])
    useEffect(() => {
        setLiveTopics([
            ...props.liveTopics.tabs[0].topics
        ])

    }, [props.liveTopics, props.meeting])

    // useEffect(() => {
    //     //update user counter number in the list
    //     setMeeting({
    //         ...meeting,
    //         ...props.meeting
    //     })
    // }, [props.meeting])

    // useEffect(() => {
    //     console.log('activeConference: ', activeConference);
    // }, [activeConference])

    const classes = useStyles();
    return (
        <div className='live-session'>
            <Typography className={classes.liveSessionsTitle} variant={"h4"} color={'secondary'}>
                Live
            </Typography>
            <List component="nav" aria-label="primary rooms live sessions">
                {liveTopics.map(topic => {
                    return (
                        <ListItem
                            style={{
                                paddingRight: "0",
                                paddingLeft: "0"
                            }}
                            key={topic.title}>
                            <ListItemLink
                                style={{
                                    paddingRight: "0.5rem",
                                    paddingLeft: "0.5rem"
                                }}
                                href={`/meeting/${activeConference}/${topic.uuid}`}
                                className={activeEvent === topic.uuid ? classes.activeSession : ''}>
                                <ListItemText
                                    style={{flex: 'none', marginRight: '5px'}}
                                    // primary={meeting.user_meetings.length}/>
                                    primary={topic.participants}/>
                                <ListItemIcon style={{ minWidth: '2.125rem'}}>
                                    <GroupIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    className={classes.listText}
                                    primary={topic.title}/>
                            </ListItemLink>
                            <Divider/>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        liveTopics: state.topics.liveTopics,
        meeting: state.meeting.meeting,
    };
}

const mapDispatchToProps = dispatch => ({
    fetchConferences: (uuid) => dispatch(fetchConferences(uuid)),
    fetchTopics: (uuid) => dispatch(fetchTopics(uuid)),
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(LiveSessions);
