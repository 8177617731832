import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import BrandingHeader from "./BrandingHeader";
import BrandingContentOne from "./BrandingContentOne";
import BrandingContentTwo from "./BrandingContentTwo";

class Branding extends Component {
    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box style={{ height: 450, overflow: 'hidden' }}>
                        <BrandingHeader data={this.props.data} text={false} />
                        <BrandingContentOne data={this.props.data} />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box style={{ height: 450, overflow: 'hidden' }}>
                        <BrandingHeader data={this.props.data} text={true} />
                        <BrandingContentOne data={this.props.data} />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box style={{ height: 450, overflow: 'hidden' }}>
                        <BrandingHeader data={this.props.data} text={false} />
                        <BrandingContentTwo data={this.props.data} />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box style={{ height: 450, overflow: 'hidden' }}>
                        <BrandingHeader data={this.props.data} text={true} />
                        <BrandingContentTwo data={this.props.data} />
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        conference: state.conferences
    };
}
const mapDispatchToProps = dispatch => ({})

export default withRouter((connect(mapStateToProps, mapDispatchToProps)(Branding)));
