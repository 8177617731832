import {
    FETCH_CONFERENCES, FETCH_CONFERENCES_ERROR
} from '../actions/types'


export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_CONFERENCES:
            return {...state, data: action.payload.data, isPageExist: true}
        case FETCH_CONFERENCES_ERROR:
            return {...state, err: action.payload, isPageExist: false}
        default:
            return state;
    }
}
