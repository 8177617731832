import React, {Component} from "react";
import 'fontsource-roboto';
import {connect} from 'react-redux'
import TextFieldCreateTopic from "../textFieldCreateTopic/ТextFieldCreateTopic";
import TopicsSectionSimple from "../topicsSection/TopicsSectionSimple";
import Typography from "@material-ui/core/Typography";
import {withSanctum} from "react-sanctum";
import {withRouter} from "react-router";

class MyConversationsPage extends Component {

    state = {
        myTopics: null,
    }

    fetchTopics = () => {
        if (this.props?.myTopics && this.props.myTopics !== this.state.myTopics) {
            this.setState({
                myTopics: this.props.myTopics
            })
        }
    }

    componentDidMount() {
        this.fetchTopics()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fetchTopics()
    }

    render() {
        return (
            <div>
                <TextFieldCreateTopic/>
                <div className='mt-24'>
                    {
                        this.state.myTopics
                            ?
                            <TopicsSectionSimple topicsSection={this.state.myTopics}/>
                            :
                            <Typography>
                                Loading ...
                            </Typography>
                    }
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        myTopics: state.topics.myTopics
    };
}

export default connect(
    mapStateToProps,
    {}
)(withSanctum(withRouter(MyConversationsPage)));
