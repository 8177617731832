import {
    FETCH_PAST_MEETINGS
} from '../actions/types'


export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_PAST_MEETINGS:
            return {...state, data: action.payload.data}
        default:
            return state;
    }
}
