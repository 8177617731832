import React, { Component } from 'react';
import { fade } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { logOut, searchTopicsByWords, showModal, themeToggle } from "../../actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    Divider,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    Menu,
    MenuItem,
    Paper,
    Popper,
    Switch
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import history from "../../history";
import { withSanctum } from "react-sanctum";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ForumIcon from "@material-ui/icons/Forum";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import PersonIcon from '@material-ui/icons/Person';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import TuneIcon from '@material-ui/icons/Tune';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 2),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: '7px 0',
    },
});

class Header extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            conferences: null,
            event_url: '',
            uuid: null,
            anchorEl: null,
            mobileMoreAnchorEl: null,
            isMenuOpen: Boolean(this.anchorEl),
            isMobileMenuOpen: Boolean(this.mobileMoreAnchorEl),
            menuEl: null,
            user: {},
            searchTopicsValue: '',
            lightModeIsOn: false,
            isMeetingPage: this.props.location.pathname.includes('meeting'),
            pagePath: ''
        }
    }

    componentDidMount() {
        // console.log(this.props)
    }

    extractPageType = (str) => {
        const arrFromPath = str.split(/[//]/);
        const extractType = arrFromPath[1];
        return extractType;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.userData !== this.state.user) {
            this.setState({
                user: this.props.userData
            })
        }

        if (this.props.conferences?.data && this.props.conferences?.data.uuid !== this.state.uuid) {
            this.setState(prevState => ({
                conferences: this.props.conferences.data,
                uuid: this.props.conferences.data.uuid,
                event_url: this.props.conferences.data.event_url,
                //if conference is event then set link path to be conference
                //type in the data base is event but is actually a conference
                //if the type of conference is company, than add company to the link path
                pagePath: this.props.conferences.data.type === 'event' ? 'conference' : this.props.conferences.data.type
            }))
        }

        if (this.props.location.pathname.includes('meeting') !== this.state.isMeetingPage) {
            this.setState({
                isMeetingPage: this.props.location.pathname.includes('meeting')
            })
        }
    }

    logout = () => {
        this.props.signOut().then(() => {
            this.props.checkAuthentication().then((response) => {
                if (!response) {
                    history.push('/')
                    window.location.reload();
                }
            })
        })
    }

    handleClickProfileMenu = (event) => {

        if (this.state.user.isLogIn) {
            this.setState({ menuEl: event.currentTarget })
        } else {
            this.props.showModal('modal-login', '')
            this.handleCloseProfileMenu()
        }
    };

    searchTopicsValue = (e) => {
        this.setState({
            searchTopicsValue: e.target.value
        })
    }

    searchTopics = (e) => {
        if (this.state.user.isLogIn) {
            e.preventDefault()
            history.push(`/${this.state.pagePath}/${this.state.uuid}`)
            this.props.searchTopicsByWords(this.state.searchTopicsValue, this.state.uuid)
        } else {
            e.preventDefault()
            this.props.showModal('modal-login', '')
        }
    }

    handleCloseProfileMenu = () => {
        this.setState({ menuEl: null })
    };

    handleLogout = () => {
        this.dispatchActionsToParent({ type: 'logout' })
    }

    dispatchActionsToParent = (data) => {
        this.logout();
        this.handleCloseProfileMenu();
    }

    handleActions = (data, e) => {
        e.preventDefault()
        if (this.state.user.isLogIn) {
            if (this.state.isMeetingPage) {
                window.open(data)
            } else {
                history.push(data)
            }

        } else {
            this.props.showModal('modal-login', '')
        }
    }


    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    handleThemes = () => {
        this.setState({
            lightModeIsOn: !this.state.lightModeIsOn
        }, () => {
            this.props.themeToggle(this.state.lightModeIsOn)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Toolbar className='header__toolbar'>
                <div className="header-wrap">
                    <div className='header__menu-logo__wrap header-item'>
                        <List className='d-flex align-center justify-flex-start'>
                            <ListItem button component={NavLink} activeClassName='Mui-selected'
                                disabled={this.state.event_url === ''}
                                to={{ pathname: this.state.event_url }}
                                exact={true}
                                target="_blank">
                                <ListItemIcon className='drawer__icon'>
                                    <ScreenShareIcon className={' style__mirror'} />
                                    <Typography style={{ marginLeft: "10px" }}>
                                        Sessions
                                    </Typography>
                                </ListItemIcon>
                            </ListItem>
                            <Tooltip
                                placement="top"
                                arrow
                                title={
                                    <React.Fragment>
                                        <Typography className={'header-tooltip'} color="inherit">All
                                            Conversations</Typography>
                                    </React.Fragment>
                                }
                            >
                                <ListItem button component={NavLink} activeClassName='Mui-selected'
                                    exact={true}
                                    target={this.state.isMeetingPage ? '_blank' : '_self'}
                                    to={`/${this.state.pagePath}/${this.state.uuid}`}
                                    onClick={(e) => {
                                        this.handleActions(`/${this.state.pagePath}/${this.state.uuid}`, e)
                                    }}
                                >
                                    <ListItemIcon className='drawer__icon'>

                                        <ForumIcon className={classes.drawerIcon} />

                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                arrow
                                title={
                                    <React.Fragment>
                                        <Typography className={'header-tooltip'} color="inherit">Subscribed
                                            Conversations</Typography>
                                    </React.Fragment>
                                }
                            >
                                <ListItem button component={NavLink} to={`/subscribed/${this.state.uuid}`}
                                    activeClassName='Mui-selected'
                                    target={this.state.isMeetingPage ? '_blank' : '_self'}
                                    onClick={(e) => {
                                        this.handleActions(`/subscribed/${this.state.uuid}`, e)
                                    }}
                                >
                                    <ListItemIcon className='drawer__icon'>
                                        <SubscriptionsIcon className={classes.drawerIcon} />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                arrow
                                title={
                                    <React.Fragment>
                                        <Typography className={'header-tooltip'} color="inherit">Contacts</Typography>
                                    </React.Fragment>
                                }
                            >
                                <ListItem button component={NavLink}
                                    activeClassName='Mui-selected'
                                    target={this.state.isMeetingPage ? '_blank' : '_self'}
                                    to={`/connections/${this.state.uuid}`}
                                    onClick={(e) => {
                                        this.handleActions(`/connections/${this.state.uuid}`, e)
                                    }}
                                >
                                    <ListItemIcon className='drawer__icon'>
                                        <GroupAddIcon className={classes.drawerIcon} />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>
                        </List>
                    </div>
                    {/* SEARCH BAR*/}
                    <Paper
                        component="form"
                        onSubmit={(e) => {
                            this.searchTopics(e)
                        }}
                        className={`${classes.search} header-item header__search__wrap`}>

                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => {
                                this.searchTopicsValue(e)
                            }}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            type="submit"
                            onSubmit={(e) => {
                                this.searchTopics(e)
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    {/* !SEARCH BAR*/}
                    {/* ACCOUNT ICON*/}
                    <div className={`${classes.sectionDesktop} header-item header__profile__wrap`}>
                        {/* <div className='theme-toggle__wrap'>
                            <Brightness3Icon />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.lightModeIsOn}
                                        onChange={() => {
                                            this.handleThemes()
                                        }}
                                        color="secondary"
                                        name="make topic public"
                                        inputProps={{ 'aria-label': 'public topic checkbox' }} />}
                                label=''
                            />
                            <WbSunnyIcon />
                        </div> */}
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            color="inherit"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                this.handleClickProfileMenu(e)
                            }}
                        >
                            <AccountCircle fontSize="large" />
                        </IconButton>
                        <Menu
                            className='menu-profile'
                            id="simple-menu"
                            anchorEl={this.state.menuEl}
                            keepMounted
                            open={Boolean(this.state.menuEl)}
                            onClose={() => {
                                this.handleCloseProfileMenu()
                            }}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >

                            <MenuItem
                                component={Link} to={`/profile/${this.state.uuid}`}
                                target={this.state.isMeetingPage ? '_blank' : '_self'}
                                onClick={() => {
                                    this.handleCloseProfileMenu()
                                }}
                            >
                                <PersonIcon />
                                <span>
                                    Profile
                                </span>
                            </MenuItem>

                            <Divider />

                            <MenuItem
                                disabled={this.state.user && this.state.conferences && this.state.user.data.id !== this.state.conferences.host.id}
                                component={Link}
                                target={this.state.isMeetingPage ? '_blank' : '_self'}
                                to={`/manage-networking/${this.state.uuid}`}
                                onClick={() => {
                                    this.handleCloseProfileMenu()
                                }}
                            >
                                <TuneIcon />
                                <span>
                                    Manage Network
                                </span>
                            </MenuItem>

                            <Divider />

                            <MenuItem
                                component={Link} to={`/branding/${this.state.uuid}`}
                                target={this.state.isMeetingPage ? '_blank' : '_self'}
                                onClick={() => {
                                    this.handleCloseProfileMenu()
                                }}
                            >
                                <ColorLensIcon />
                                <span>
                                    Branding
                                </span>
                            </MenuItem>

                            <Divider />

                            <MenuItem onClick={() => {
                                this.handleLogout()
                            }}>
                                <ExitToAppIcon />
                                <span>
                                    Log Out
                                </span>
                            </MenuItem>

                        </Menu>
                    </div>


                    {/* ACCOUNT ICON*/}
                </div>

            </Toolbar>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        drawer: state.drawer,
        userData: state.user,
        conferences: state.conferences
    }
}

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(logOut()),
    showModal: (type, data) => dispatch(showModal(type, data)),
    searchTopicsByWords: (data, conferenceUUId) => dispatch(searchTopicsByWords(data, conferenceUUId)),
    themeToggle: (data) => dispatch(themeToggle(data)),

})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(withSanctum(Header))));
