import React, {Component} from "react";
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';


class NotFound extends Component {

    componentDidMount() {}

    render() {
        return (

            <Typography variant="h1" component="h2" gutterBottom>
                Not Found Page
            </Typography>
        );
    }

}

export default (NotFound);
