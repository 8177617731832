import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Tabs, Tab, Box, Typography, Grid, List, ListItem, Paper } from "@material-ui/core";

const styles = theme => ({
    root: {
        maxWidth: '100%',
        marginBottom: 5,
        backgroundColor: theme.palette.primary.main
    }
});

class BrandingContentOne extends Component {
    render() {
        const { classes } = this.props;

        let styles = {};
        if (this.props.data.background_type === 'image') {
            styles = {
                background: {
                    backgroundImage: 'url(' + this.props.data.branding_background_image + ')',
                    height: 'calc(100% - 80px)'
                }
            }
        } else if (this.props.data.background_type === 'color') {
            styles = {
                background: {
                    backgroundColor: this.props.data.branding_background_color,
                    height: 'calc(100% - 80px)'
                }
            }
        }
        return (
            <div className="branding__preview__background" style={styles.background}>
                <Box className={classes.root} p={1} sx={{ overflow: 'hidden' }}>
                    <div className='d-flex align-center'>
                        <Typography color='secondary'>Live&nbsp;now</Typography>
                        <List className='d-flex align-center mr-auto' style={{ padding: 0 }}>
                            <ListItem>ALL</ListItem>
                            <ListItem>OPEN</ListItem>
                        </List>
                    </div>
                    <Paper style={{ padding: 10 }}></Paper>
                </Box>
                <Box className={classes.root} p={1} sx={{ overflow: 'hidden' }}>
                    <div className='d-flex align-center'>
                        <Typography color='secondary'>Upcoming</Typography>
                        <List className='d-flex align-center mr-auto'>
                            <ListItem>BY&nbsp;DATE</ListItem>
                            <ListItem>BY&nbsp;POPULARITY</ListItem>
                        </List>
                    </div>
                    <Paper style={{ padding: 10 }}></Paper>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(BrandingContentOne);

