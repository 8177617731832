import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {KeyboardTimePicker} from "@material-ui/pickers";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {DateTime} from "luxon";


const styles = theme => ({
    dateInputField: {
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid #ffffff !important`,
        },
        '& label.Mui-focused': {
            color: `#ffffff`,
        }
    }
});

class DatePicker extends Component {

    state = {
        date: this.props.time,
        type: this.props.type,
        label: this.props.type === 'startDate' ? 'Start time' : 'End time'
    }

    changeTimeDate = (date) => {
        this.setState({date: date});
    }

    dispatchTimeDate = () => {
        //sparcmore - 2021-10-12 13:35, Sofia/Bulgaria
        // console.log(this.state.date.toFormat('HH:mm'));
        this.props.timePickerData({type: this.state.type, date: this.state.date});
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        const {classes} = this.props;
        return (
            // <MuiPickersUtilsProvider utils={LuxonUtils}>
                <div className='picker__date__wrap'>
                    <KeyboardTimePicker
                        label={this.state.label}
                        className={classes.dateInputField}
                        ampm={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        PopoverProps={{
                            anchorOrigin: {horizontal: "right", vertical: "bottom"},
                            transformOrigin: {horizontal: "right", vertical: "top"}
                        }}
                        placeholder="13:00 PM"
                        mask="__:__ _M"
                        variant="inline"
                        value={this.state.date}
                        // minDate={DateTime.local()}
                        onOpen={() => {
                            // this.checkDiss(type)
                            // this.dispatchTimeDate()
                        }}
                        onClose={() => {
                            this.dispatchTimeDate()
                        }}
                        onChange={(date) => {
                            this.changeTimeDate(date)
                        }}
                    />


                </div>
            // </MuiPickersUtilsProvider>
        );
    }

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = dispatch => ({})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(DatePicker)));
