import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers';
import reduxThunk from 'redux-thunk'
import history from "./history";
import {Router} from "react-router-dom";
import {Sanctum} from "react-sanctum";

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import {DateTime}  from "luxon";
import CustomLuxonUtils from "./utilities/CustomLuxonUtils";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)))

const sanctumConfig = {
    api_url: process.env.REACT_APP_API_URL,
    csrf_cookie_route: "sanctum/csrf-cookie",
    signin_route: "login",
    signout_route: "logout",
    user_object_route: "api/user",
};

ReactDOM.render(
    <Sanctum config={sanctumConfig}>
        <Provider store={store}>
            <Router history={history}>
                <MuiPickersUtilsProvider
                    utils={CustomLuxonUtils}
                >
                    <App/>
                </MuiPickersUtilsProvider>
            </Router>
        </Provider>
    </Sanctum>,
    document.getElementById('root')
);
