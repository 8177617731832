import React, { Component } from 'react';
import '../assets/styles/main.css';
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import BrandingPage from "./pages/BrandingPage";
import NotFound from "./pages/NotFound";
import history from "../history";
import { Route, Switch, useLocation } from "react-router-dom";
import Header from "./header/Header";
import {
    AppBar,
    createMuiTheme, Divider,
    ThemeProvider
} from '@material-ui/core';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import MyConversationsPage from "./pages/MyConversationsPage";
import ModalContent from "./modals/ModalContent";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ConnectionsPage from "./pages/ConnectionsPage";
import Dashboard from "./pages/Dashboard";
import { withSanctum } from "react-sanctum";
import {
    fetchConnections,
    fetchTopics,
    login,
    logOut,
    showModal,
    fetchUpcomingMeetings,
    fetchPastMeetings, fetchConferences, conversationsWithFlag, usersWithFlag
} from "../actions";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import SubscribedPage from "./pages/SubscribedPage";
import Meeting from "./pages/Meeting";
import LogoInitAlien from "./Logo/LogoInitAlien";
import ManageNetworking from "./pages/ManageNetworking";
import { removeQuotes } from "./helpers/functions"
import { Skeleton } from "@material-ui/lab";

const styles = theme => ({
    root: {
        display: 'flex',
    },

    overrides: {
        MuiButton: {
            root: {
                fontSize: '1rem',
            },
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `100%`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },

    content: {
        padding: '1.5rem',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
});

class App extends Component {

    state = {
        drawerIsOpen: this.props.drawer.drawerIsOpen,
        //true is the dark
        theme: null,
        isAuth: false,
        user: this.props.userData,
        isAuthChecked: false,
        sponsor_logo: null,
        event_logo: null,
        isConferenceExist: null,
        uuid: null,
        conference: null,
        isUserHost: null,
    }


    componentDidMount() {
        this.checkAuth();
        this.checkConference()
    }

    checkNavigation = () => {

        if (!this.state.user.isLogIn && this.props.location.pathname !== '/') {
            // history.push('/')
            this.props.showModal('modal-login', '')
        }
    }

    checkAuth = () => {
        this.props.checkAuthentication()
            .then((response) => {
                this.setState({
                    isAuth: response,
                    //this shows if we already get the response from checkAuthentication()
                    isAuthChecked: true
                })
                if (response) {
                    this.props.login(this.props.user)
                } else {
                    this.props.logOut()
                }
            })
            .then(() => {
                this.checkNavigation();
            })
    }

    getUUIDParamFromURL = () => {
        let pathNameArray = this.props.location.pathname.split('/')
        if (pathNameArray[2] !== undefined) {
            let uuid = removeQuotes(pathNameArray[2])
            return uuid
        } else {
            this.setState(prevState => ({
                isConferenceExist: false
            }))
            return null
        }

    }

    checkConference = () => {
        this.props.fetchConferences(this.getUUIDParamFromURL())
            .then(() => {
                if (this.props.conferences.isPageExist) {
                    this.setState(prevState => ({
                        conference: this.props.conferences.data,
                        uuid: this.props.conferences.data.uuid,
                        isConferenceExist: true
                    }))
                    if (this.props.conferences.data.branding) {
                        this.checkBranding(this.props.conferences.data.branding)
                    }

                } else {
                    this.setState(prevState => ({
                        isConferenceExist: false
                    }))
                }
            })
            .then(() => {
                if (this.state.isConferenceExist) {
                    this.props.fetchTopics(this.state.uuid)
                }
            })
    }

    checkBranding = (branding) => {
        this.setState({
            event_logo: branding.logo.image,
            branding_logo_url: branding.logo.url,

            header_type: branding.header.background.type,
            branding_header_image: branding.header.background.data,
            branding_header_color: branding.header.background.data,
            branding_header_text_color: branding.header.color,

            background_type: branding.background.type,
            branding_background_image: branding.background.data,
            branding_background_color: branding.background.data
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.userData && this.props.userData !== this.state.user) {
            this.setState({ user: this.props.userData })
        }


        if (this.props?.theme && this.props.theme.data !== this.state.theme) {
            this.setState({ theme: this.props.theme.data })
        }
    }

    unAuthorizedLayout = () => {
        return (
            <Switch>
                <Route path='/terms-of-use' exact component={TermsOfUse} />
                <Route path='/privacy-policy' exact component={PrivacyPolicy} />
                <Route path='*' component={NotFound} />
            </Switch>
        )
    }

    authorizedHostLayout = () => {
        return (
            <Switch>
                {/*<Route path='/' exact component={HomePage}/>*/}
                <Route path='/conference/:uuid' exact component={HomePage} />
                <Route path='/company/:uuid' exact component={HomePage} />
                <Route path='/subscribed/:uuid' exact component={SubscribedPage} />
                <Route path='/profile/:uuid' exact component={ProfilePage} />
                <Route path='/branding/:uuid' exact component={BrandingPage} />
                <Route path='/conversations' exact component={MyConversationsPage} />
                <Route path='/connections/:uuid' exact component={ConnectionsPage} />
                <Route path='/dashboard' exact component={Dashboard} />
                <Route path='/manage-networking/:uuid' exact component={ManageNetworking} />
                <Route path='/meeting/:uuid/:uuidTopic' exact component={Meeting} />
                <Route path='/terms-of-use' exact component={TermsOfUse} />
                <Route path='/privacy-policy' exact component={PrivacyPolicy} />
                <Route path='*' component={NotFound} />
            </Switch>
        )
    }

    authorizedUnHostLayout = () => {
        return (
            <Switch>
                {/*<Route path='/' exact component={HomePage}/>*/}
                <Route path='/conference/:uuid' exact component={HomePage} />
                <Route path='/company/:uuid' exact component={HomePage} />
                <Route path='/subscribed/:uuid' exact component={SubscribedPage} />
                <Route path='/profile/:uuid' exact component={ProfilePage} />
                <Route path='/branding/:uuid' exact component={BrandingPage} />
                <Route path='/conversations' exact component={MyConversationsPage} />
                <Route path='/connections/:uuid' exact component={ConnectionsPage} />
                <Route path='/dashboard' exact component={Dashboard} />
                <Route path='/meeting/:uuid/:uuidTopic' exact component={Meeting} />
                <Route path='/terms-of-use' exact component={TermsOfUse} />
                <Route path='/privacy-policy' exact component={PrivacyPolicy} />
                <Route path='*' component={NotFound} />
            </Switch>
        )
    }

    checkHostUser = () => {
        if (this.state.conference.host.id === this.state.user.data.id) {
            return this.authorizedHostLayout()
        } else {
            return this.authorizedUnHostLayout()
        }
    }


    handleLayout = () => {
        if (this.state.isAuthChecked && this.state.conference) {
            if (this.state.isAuth) {
                return this.checkHostUser()
            } else {
                return this.unAuthorizedLayout()
            }
        } else {
            return (
                <Typography>
                    Loading ...
                </Typography>
            )
        }

    }

    logout = () => {
        this.props.signOut().then(() => {
            this.props.checkAuthentication().then((response) => {
                if (!response) {
                    history.push('/')
                    window.location.reload();
                }
            })
        })
    }

    render() {
        const themeLight = createMuiTheme({
            palette: {
                type: 'light',
                primary: {
                    main: '#ffffff'
                },
                secondary: {
                    main: '#ee220c'
                },
                link: {
                    main: '#1E1E1E'
                },
                background: {
                    default: '#ffffff'
                },
                light: {
                    default: '#ffffff'
                },
                lightDimmer: {
                    default: '#9d9d9d'
                }
            }
        });
        const themeDark = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: '#1E1E1E'
                },
                secondary: {
                    main: '#FE311B',
                },
                link: {
                    main: '#ffffff',
                },
                background: {
                    default: '#141414'
                },
                light: {
                    default: '#ffffff'
                },
                lightDimmer: {
                    default: '#9d9d9d'
                }
            },
            overrides: {
                MuiPickersToolbar: {
                    toolbar: {
                        backgroundColor: '#ee220c',
                    },
                },
                MuiPickersCalendarHeader: {
                    switchHeader: {
                        // backgroundColor: lightBlue.A200,
                        // color: "white",
                    },
                },
                MuiPickersDay: {
                    day: {
                        color: '#f5f5f5',
                    },
                    daySelected: {
                        backgroundColor: 'rgba(238,34,12, 1)',
                    },
                    current: {
                        color: '#ffffff',
                    },
                },
                MuiPickersModal: {
                    dialogAction: {
                        color: '#ffffff',
                    },
                },
                MuiCssBaseline: {
                    '@global': {
                        '*': {
                            'scrollbar-width': 'thin',
                            'scrollbar-color': '#ee220c #1E1E1E',
                        },
                        '*::-webkit-scrollbar': {
                            width: '0.313rem',
                            height: '0.313rem',
                        },
                        '*::-webkit-scrollbar-thumb': {
                            background: '#ee220c',
                            borderRadius: '0.313rem'
                        }
                    }
                }
            },
        });

        let bHeader = {};
        if (this.state.header_type === 'image') {
            bHeader = {
                headerBackground: {
                    backgroundImage: 'url(' + this.state.branding_header_image + ')'
                },
                headerTextColor: {
                    color: this.state.branding_header_text_color
                }
            }
        } else if (this.state.header_type === 'color') {
            bHeader = {
                headerBackground: {
                    backgroundColor: this.state.branding_header_color
                },
                headerTextColor: {
                    color: this.state.branding_header_text_color
                }
            }
        }


        if (this.props.location.pathname.includes('branding')) {
            const bContent = document.querySelector("body");
            bContent.style.backgroundColor = '#141414';
        } else {
            const bContent = document.querySelector("body");
            if (this.state.background_type === 'image') {
                bContent.style.backgroundImage = 'url(' + this.state.branding_background_image + ')';
            } else if (this.state.background_type === 'color') {
                bContent.style.backgroundColor = this.state.branding_background_color;
            }
        }

        const { classes } = this.props;
        return (

            <ThemeProvider theme={this.state.theme ? themeLight : themeDark}>
                <CssBaseline />

                <div className="wrapper">
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={`header__toolbar d-flex align-center justify-between`} style={bHeader.headerBackground}>

                            {/* className={'toolbar__phantom'} */}

                            <LogoInitAlien variant={'h6'} img={this.state.event_logo} type="own" />
                            {/*<LogoInitAlien variant={'h6'} img={marsNasa}/>*/}
                            {
                                this.state.conference
                                    ?
                                    <Typography variant="h4" style={bHeader.headerTextColor}>
                                        {this.state.conference.name}
                                    </Typography>
                                    :
                                    <Typography variant="h3" style={{ width: '400px', height: '56px' }}>
                                        <Skeleton />
                                    </Typography>
                            }

                            <LogoInitAlien variant={'h6'} img={this.state.sponsor_logo} type="sponsor" />
                            {/*<LogoInitAlien variant={'h6'} img={marsSweet}/>*/}
                        </Toolbar>
                        <Divider />
                        {/*HEADER*/}
                        <Header />
                        {/*!HEADER*/}
                    </AppBar>

                    <main className={clsx(classes.content, {
                        // THE MAIN SECTION NEEDS FROM DRAWER STATE
                        [classes.contentShift]: this.state.drawerIsOpen,
                    })}>
                        <Toolbar className={'toolbar__phantom'} />
                        <Toolbar className={'toolbar__phantom'} />
                        {
                            this.state.isConferenceExist !== null
                                ?
                                this.state.isConferenceExist
                                    ?
                                    this.handleLayout()
                                    :
                                    <NotFound />
                                :
                                <Typography>
                                    Loading ...
                                </Typography>
                        }
                    </main>
                </div>

                {/*MODAL DIALOG*/}
                <ModalContent />
                {/*!MODAL DIALOG*/}

            </ThemeProvider>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        drawer: state.drawer,
        userData: state.user,
        theme: state.theme,
        conferences: state.conferences
    };
}

const mapDispatchToProps = dispatch => ({
    login: data => dispatch(login(data)),
    logOut: () => dispatch(logOut()),
    showModal: (type, data) => dispatch(showModal(type, data)),
    fetchTopics: (uuid) => dispatch(fetchTopics(uuid)),
    fetchConnections: () => dispatch(fetchConnections()),
    fetchUpcomingMeetings: () => dispatch(fetchUpcomingMeetings()),
    fetchConferences: (uuid) => dispatch(fetchConferences(uuid))
})

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(withSanctum(withRouter(App))));
