import React, {Component} from "react";
import 'fontsource-roboto';
import {fetchConnections} from "../../actions";
import {connect} from 'react-redux'
import TextFieldSearchConnections from "../textFieldSearchConnections/ТextFieldSearchConnections";
import {Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import CustomPaginationActionsTable from "../tables/Table";

const styles = theme => ({});

class ConnectionsPage extends Component {

    state = {
        connections: null,
        searchedWord: this.props.searchedWord
    }

    componentDidMount() {
        if( this.props.connections?.data) {
            this.setState({
                connections: this.props.connections.data
            })
        } else {
            this.props.fetchConnections()
                .then(() => {
                    this.setState({
                        connections: this.props.connections.data
                    })
                })
        }

        this.filteredAttendeeList();
    }

    // filteredAttendeeList returns array when you are in /connections page
    //but when you switch from another page, it returns (data[{},{}])
    //let connections = .... fix this
    // @ToDo it is good to fix fetching the different receiving type of data
    filteredAttendeeList = () => {
        if (this.props.searchedWord) {
            let connections = this.state.connections && this.state.connections.data ? this.state.connections.data : this.state.connections
            return (
                connections.filter((item) => {
                        return item.name.toLowerCase().indexOf(this.props.searchedWord.toLowerCase()) !== -1
                    }
                )
            )
        } else {
            return this.state.connections && this.state.connections.data ? this.state.connections.data : this.state.connections
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.connections?.data && this.state.connections !== prevProps.connections.data) {
            this.props.fetchConnections()
                .then(() => {
                    this.setState({
                        connections: this.props.connections.data
                    })
                })

        }
        this.filteredAttendeeList()

    }

    render() {
        const {classes} = this.props;
        return (
            <div>

                <TextFieldSearchConnections/>

                <div className='connections__list'>
                    {
                        this.state.connections
                            ?
                            <CustomPaginationActionsTable population={this.filteredAttendeeList()}/>
                            :
                            <Typography>
                                Loading...
                            </Typography>

                    }

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        connections: state.connections.data,
        searchedWord: state.connections.search
    };
}


const mapDispatchToProps = dispatch => ({
    fetchConnections: () => dispatch(fetchConnections())
})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ConnectionsPage))
