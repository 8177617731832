import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    DialogContent, Link
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal, LILogin} from "../../actions";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LogoInit from "../Logo/LogoInit";
import {withSanctum} from "react-sanctum";

const styles = theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#0077b5',
        color: '#ffffff'
    },
});


class ModalLogin extends Component {

    state = {
        modalTopic: this.props.modal.data.content,
        isTopicPublic: false
    }

    componentDidMount() {
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-login')
    };


    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__login'>

                    {/*SPARCMORE LOGO*/}
                    <LogoInit variant={'h4'}/>
                    {/* !SPARCMORE LOGO*/}

                    <Typography variant='h5' className='sign'>
                        Sign In
                    </Typography>
                    <Typography variant='h6'>
                        SPARCmore provides group video meetings with like-minded <br/>
                        professionals for meaningful conversations.
                    </Typography>
                    <div>
                        <div className='InLogin__wrap'>
                            <div className={`wrap-link__LI ${classes.form}`}>
                                <Link href="https://www.ampslide.com/"
                                      className={`login__btn-linkedin ${classes.submit}`}>
                                    {/*<LinkedInIcon/>*/}
                                    <span>Continue to AmpSlide</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/*<Typography variant='body2'>*/}
                    {/*    By continuing, you agree that you have read and agreed to*/}
                    {/*    SPARCmore’s <Link color='secondary' href='/terms-of-use' target='_blank'*/}
                    {/*                      className='links-primary'>Terms of Use</Link> and <Link color='secondary'*/}
                    {/*                                                                              href='/privacy-policy'*/}
                    {/*                                                                              target='_blank'*/}
                    {/*                                                                              className='links-primary'>Privacy*/}
                    {/*    Policy</Link>.*/}
                    {/*</Typography>*/}
                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: data => dispatch(closeModal(data))
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(withSanctum(ModalLogin))));
