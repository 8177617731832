import {
    FETCH_TIME_ZONES
} from '../actions/types'
import {DateTime} from "luxon";


export default (state = {}, action) => {
    let arr = [];
    const currentTimeZone = DateTime.local().zoneName;
    let currentTimeZoneOption = '';
    switch (action.type) {
        case FETCH_TIME_ZONES:
            action.payload.map((tzItem) => {

                //one item from array of timezones
                let utc = tzItem.text.substring(0, 11)

                // utc returns the offset: (UTC-07:00)
                return (
                    tzItem.utc.map(option => {
                        //this iterate all capitals in the current time zone
                        //"America/Creston",
                        //"America/Dawson_Creek",
                        //"America/Hermosillo", .....
                        if (!arr.some(optionTz => optionTz.tz === option)) {
                            // this add unique option into array
                            arr.push({tz: option, text:utc + ' ' + option })

                            if(currentTimeZone === option) {
                                currentTimeZoneOption = {tz: option, text:utc + ' ' + option };
                            }
                        } else {
                            return null
                        }
                    })
                )
            })
            return {
                ...state,
                options: arr,
                currentTimeZone: currentTimeZoneOption
            }
        default:
            return {
                ...state,
                currentTimeZone: currentTimeZone
            };
    }
}
