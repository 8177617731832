import {
    FETCH_CONNECTIONS,
    SEARCH_CONNECTIONS
} from '../actions/types';
import _ from 'lodash';


export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_CONNECTIONS:
            return {...state, data: action.payload}
        case SEARCH_CONNECTIONS:
            return {...state, search: action.data}
        default:
            return state;
    }
}
