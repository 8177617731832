import React from 'react';
// import {useJitsi} from 'react-jutsu';
import Jitsi from 'react-jitsi'
import {validateURL} from "../../utilities/methods";


function JitsiVideo({
                        room,
                        name,
                        password,
                        userRole,
                        sessionId,
                        eventName,
                        sessionSponsorURL,
                        sessionSponsorImageLink
                    }) {

    const roomName = eventName
    let validateSessionSponsorURL;
    const parentNode = 'jitsi-container'
    const interfaceConfig = {
        APP_NAME: "Sparcmore",
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        HIDE_INVITE_MORE_HEADER: true,
        MOBILE_APP_PROMO: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        TOOLBAR_BUTTONS: userRole === 'host' ? [
            'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
            'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
            'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
            'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
            'tileview', 'select-background', 'download', 'help', 'mute-everyone', 'mute-video-everyone', 'security'
        ] : [
            'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
            'fodeviceselection', 'hangup', 'chat',
            'livestreaming', 'etherpad', 'sharedvideo', 'raisehand',
            'videoquality', 'filmstrip', 'feedback',
            'tileview', 'select-background', 'download', 'help', 'mute-everyone', 'mute-video-everyone', 'security'
        ],
    };
    const config = {
        prejoinPageEnabled: false,
        enableWelcomePage: false,
        // requireDisplayName - require to add name
        requireDisplayName: false,
        disableInviteFunctions: false,
        useNicks: true,
        enableCalendarIntegration: userRole === 'host',
        disableProfile: userRole !== 'host',
        displayName: name,
        subject: 'SPARCmore',
        remoteVideoMenu: {
            // If set to true the 'Kick out' button will be disabled.
            disableKick: userRole !== 'host'
        },
        // Every participant after the Nth will start video muted.
        startVideoMuted: 10,
        startWithVideoMuted: false,
        startWithAudioMuted: false
    }

    const handleAPI = (JitsiMeetAPI) => {
        JitsiMeetAPI.on('passwordRequired', () => {
            JitsiMeetAPI.executeCommand('password', password);
        });
    };

    return (
        <React.Fragment>
            <div className='over-the-footer'
                 style={{zIndex: '999', position: 'relative'}}>
                <div className='jitsi__wrap'>
                    <div className='organizer__jitsi-title__wrap'>

                        {
                            sessionSponsorImageLink
                                ?
                                <div className='sponsor-img__wrap'>
                                    <a href={sessionSponsorURL ? validateURL(sessionSponsorURL) : '/'} target='_blank'
                                       rel="noopener noreferrer">
                                        <img src={sessionSponsorImageLink} alt={sessionSponsorURL}/>
                                    </a>
                                </div>
                                :
                                null
                        }
                    </div>

                    <Jitsi
                        onAPILoad={handleAPI}
                        roomName={password}
                        displayName={name}
                        config={config}
                        password={password}
                        interfaceConfig={interfaceConfig}
                        containerStyle={{width: '100%', height: '73vh'}}
                    />
                </div>
            </div>
        </React.Fragment>

    )
}

export default JitsiVideo;
