import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse, Grid, Paper,
    Tab,
    Tabs
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import * as PropTypes from "prop-types";
import SwipeableViews from 'react-swipeable-views';
import UserCard from "../userCards/UserCard";
import {DateTime} from "luxon";

const styles = theme => ({
    root: {
        maxWidth: '100%',
        backgroundColor: theme.palette.primary.main
    },

    control: {
        padding: theme.spacing(1)
    },
    maxHeightSection: {
        maxHeight: '24.063rem'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
});


SwipeableViews.propTypes = {
    axis: PropTypes.any,
    onChangeIndex: PropTypes.func,
    children: PropTypes.node
};

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

class TopicsSection extends Component {

    state = {
        expanded: true,
        tabIndex: 0,
        tabValue: 0,
        topicsSection: null,
        // topicsSectionHeight: 385,
    }

    //divElement is for getting height of userCard and set it to the parent component
    //and when dynamically set it on resize
    divElement = React.createRef();

    componentDidMount() {
        if (this.props?.topicsSection) {
            this.setState({topicsSection: this.props.topicsSection}, () => {
                // this.getHeightOfElOnResize()
            })
        }

        // window.addEventListener('load', this.getHeightOfElOnResize);
        // window.addEventListener('resize', this.getHeightOfElOnResize);
    }

    componentDidUpdate() {
        //it's not very good decision but it works
        // @ToDo fix or remove the setState callback
        if (this.props?.topicsSection && this.state.topicsSection !== this.props.topicsSection) {
            this.setState({topicsSection: null}, ()=>{
                this.setState({topicsSection: this.props.topicsSection})
            })

        }
    }


    componentWillUnmount() {
        // window.removeEventListener('resize', this.getHeightOfElOnResize);
        // window.removeEventListener('load', this.getHeightOfElOnResize);
    }

    // getHeightOfElOnResize = () => {
    //     //this return the height of the first element
    //     //we should get the height of the highest element
    //     this.setState({
    //         topicsSectionHeight: this.divElement.current.clientHeight + 15
    //     })
    //
    // }

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
    };

    a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    handleChange = (event, newValue) => {
        this.setState({tabValue: newValue})
        event.stopPropagation()
    };

    handleChangeIndex = (index) => {
        this.setState({tabIndex: index})
    };

    orderUserCards = (a, b) => {
        if (a && b) {
            const titleA = a.props.children.props.topic.topicTitle.toUpperCase(); // ignore upper and lowercase
            const titleB = b.props.children.props.topic.topicTitle.toUpperCase(); // ignore upper and lowercase

            // compare dates ad times by timestamps, but the time stamps are incorrect so
            // you have to compare the years, months, days, hours and minutes
            const startDateA = DateTime.fromISO(a.props.children.props.topic.startDate).ts;
            const startDateB = DateTime.fromISO(b.props.children.props.topic.startDate).ts;
            const endDateA = DateTime.fromISO(a.props.children.props.topic.endDate).ts;
            const endDateB = DateTime.fromISO(b.props.children.props.topic.endDate).ts;


            if (!isNaN(Number(startDateA)) && !isNaN(Number(startDateB))) {
                if (Number(startDateA) > Number(startDateB)) {
                    return 1;
                } else if (Number(startDateA) < Number(startDateB)) {
                    return -1;
                } else if (Number(endDateA) > Number(endDateB)) {
                    return 1;
                } else if (Number(endDateA) < Number(endDateB)) {
                    return -1;
                } else {
                    return 0;
                }

            } else {
                if (titleA > titleB) {
                    return 1;
                } else if (titleA < titleB) {
                    return -1;
                } else {
                    return 0;
                }
            }

        } else {
            return null
        }

    }

    renderTopics = (topics) => {
        if (topics.length !== 0) {
            return (
                topics.map((topic, index) => {
                    return (
                        <Grid key={index} item md={6}
                              ref={this.divElement}
                        >
                            <UserCard
                                topic={topic}/>
                        </Grid>
                    )
                })
                // .sort(this.orderUserCards)
            )
        } else {
            return (
                <Grid
                    item
                    ref={this.divElement}
                    md={6}
                >
                    {/*IF THERE ISN'T ANY EVENTS*/}
                    <Paper variant="outlined" className='p-16'>
                        <Typography paragraph>
                            No conversations at this time.
                        </Typography>
                        <Typography paragraph>
                            SUBSCRIBE to upcoming virtual group conversations to be notified
                            of future ones.
                        </Typography>
                    </Paper>
                    {/*IF THERE ISN'T ANY EVENTS*/}
                </Grid>
            )
        }
    };

    renderTopicSectionContent = () => {
        const {classes} = this.props;
        return (
            <Card className={classes.root}>
                <div
                    className='card-header__wrap'
                    onClick={this.handleExpandClick}
                >
                    <CardContent>
                        <Typography
                            variant='h5'
                            color='secondary'

                        >
                            {this.state.topicsSection.title}
                        </Typography>
                    </CardContent>

                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        className='mr-auto'
                    >
                        {
                            this.state.topicsSection.tabs.map((tab, index) => {
                                return (
                                    <Tab key={index} label={tab.title} {...this.a11yProps(index)} />
                                )
                            })
                        }
                    </Tabs>

                    <CardActions disableSpacing>

                        <IconButton
                            color='secondary'
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            aria-expanded={this.state.expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </CardActions>
                </div>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <SwipeableViews
                            axis={this.props.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={this.state.tabValue}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            {/*PANEL*/}
                            {
                                this.state.topicsSection.tabs.map((tab, index) => {
                                    return (
                                        <TabPanel
                                            className={`${classes.control} ${this.state.topicsSection.title === 'Live Now' ? classes.maxHeightSection : ''} tab-panel__wrap`}
                                            key={index}
                                            value={this.state.tabValue}
                                            index={index}
                                            dir={this.props.theme.direction}
                                            // style={{height: this.state.topicsSectionHeight}}
                                        >
                                            <Grid container spacing={2}>
                                                {this.renderTopics(tab.topics)}
                                            </Grid>
                                        </TabPanel>
                                    )
                                })
                            }
                            {/*!PANEL*/}
                        </SwipeableViews>
                    </CardContent>
                </Collapse>
            </Card>
        )
    }

    render() {
        return (
            <div className='collapse-section__wrap'
                 style={{visibility: this.state.topicsSectionHeight === 0 ? "hidden" : "visible"}}
            >
                {
                    // I can't set this.state.topicsSection &&  this.state.topicsSectionHeight === 0
                    //because this not going to render
                    this.state.topicsSection
                        ?
                        this.renderTopicSectionContent()
                        :
                        <Typography>
                            Loading ...
                        </Typography>
                }
            </div>
        );
    }

}

export default withStyles(styles, {withTheme: true})(TopicsSection);
