import React, {Component} from "react";
import 'fontsource-roboto';
import {connect} from 'react-redux'
import {
    Grid,
    LinearProgress,
    Typography
} from "@material-ui/core";
import {withSanctum} from "react-sanctum";
import {withRouter} from "react-router";
import {joinMeeting} from "../../actions";
import CancelIcon from '@material-ui/icons/Cancel';
import JitsiVideo from "../jitsi/JitsiVideo";
import ListLiveUsers from "../listLiveUsers/ListLiveUsers";
import {withStyles} from "@material-ui/core/styles";
import LiveSessions from "../drawer/LiveSessions";

const styles = theme => ({

    meetingRoot: {
        backgroundColor: theme.palette.primary.main,
    },
    meetingItemsWrap: {
        margin: '0 1rem'
    }
})


class Meeting extends Component {

    state = {
        user: null,
        meeting: null,
        liveTopics: null,
        meetingUUId: null,
        conferenceUUId: null
    }

    fetchTopic = () => {
        if (this.props.match.params.uuidTopic &&
            this.state.meetingUUId !== this.props.match.params.uuidTopic &&
            this.props.liveTopics?.tabs[0]?.topics &&
            this.props?.userData !== this.state.user
        ) {


            this.setState({
                meetingUUId: this.props.match.params.uuidTopic,
                conferenceUUId: this.props.match.params.uuid
            }, () => {
                let allTopics = this.props.liveTopics.tabs[0].topics
                let uuid = this.props.match.params.uuidTopic;

                let topic = allTopics.find(obj => {
                    return obj.uuid === uuid
                })

                if (topic) {
                    this.setState({
                        isMeetingExist: true
                    })
                    this.props.joinMeeting({topic_id: topic.id})
                        .then(() => {
                            // set the joined meeting
                            this.setState({
                                meeting: this.props.meeting,
                                user: this.props.userData
                            })
                        })

                } else {
                    console.log('noTopic');
                    this.setState({
                        isMeetingExist: false
                    })
                }
            })
        }
    }

    componentDidMount() {
        this.fetchTopic();
    }

    componentDidUpdate() {
        this.fetchTopic();
    }

    componentWillMount() {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        this.setState({
            meeting: null,
            liveTopics: null,
            meetingUUId: null
        })

        document.body.style.height = null;
        document.body.style.overflow = null;
    }

    renderNoMeeting = () => {
        return (
            <div className='fullScreen__components-centered'>
                <Typography variant={"h3"}>
                    There is no such conversation
                </Typography>
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item md={10}
                          style={{
                              display: 'flex',
                              justifyContent: 'center'
                          }}
                    >
                        <CancelIcon
                            style={{
                                fontSize: '200px'
                            }}
                            color={"secondary"}/>
                    </Grid>
                </Grid>

            </div>
        )
    }

    renderLoadingMeeting = () => {
        return (
            <div className='fullScreen__components-centered'>
                <Typography variant={"h2"}>
                    We are preparing your conversation
                </Typography>
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item md={6}>
                        <LinearProgress color="secondary"/>
                    </Grid>
                </Grid>
            </div>
        )
    }

    setUserRole = () => {
        if (this.state.meeting.host === this.state.user.data.id) {
            return 'host'
        } else {
            return 'guest'
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.meetingRoot}>
                {
                    this.props.liveTopics
                        ?
                        this.state.isMeetingExist
                            ?
                            this.state.meeting
                                ?
                                <Grid container spacing={3}>
                                    {/*SECTIN WITH TITLE*/}
                                    <Grid item xs={12} className={classes.meetingItemsWrap}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} md={9} lg={10}>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={2} md={4} lg={2}/>
                                                    <Grid item xs={10} md={8} lg={10}>
                                                        <Typography variant={"h3"}>
                                                            {this.state.meeting.title}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={2} md={3} lg={2}/>
                                        </Grid>
                                    </Grid>
                                    {/*!SECTIN WITH TITLE*/}
                                    <Grid item xs={12} className={classes.meetingItemsWrap}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} md={9} lg={10}>
                                                <Grid container spacing={3}>
                                                    <Grid item className={`${this.setUserRole()} over-the-footer`}
                                                          sm={2} md={4} lg={2}>
                                                        <LiveSessions
                                                            conferenceUUId={this.state.conferenceUUId}
                                                            activeTopicUUId={this.state.meetingUUId}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={10} md={8} lg={10}>
                                                        <div className='container-jitsi'>
                                                            <JitsiVideo
                                                                room={this.state.meeting.title}
                                                                name={this.state.user.data.name}
                                                                password={this.state.meeting.uuid}
                                                                userRole={this.setUserRole()}
                                                                sessionId={this.state.meeting.id}
                                                                eventName={this.state.meeting.title}
                                                                // sessionSponsorURL={'this.state.sponsor_url'}
                                                                // sessionSponsorImageLink={'this.state.image_link'}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={`${this.setUserRole()} over-the-footer`} sm={2} md={3}
                                                  lg={2}>
                                                <div className='user-li-list'>
                                                    <ListLiveUsers
                                                        topicId={this.state.meeting.id}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                this.renderLoadingMeeting()
                            :
                            this.renderNoMeeting()
                        :
                        this.renderLoadingMeeting()


                }
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        meeting: state.meeting.meeting,
        meetingUUId: state.meeting.meetingUUId,
        liveTopics: state.topics.liveTopics,
        userData: state.user
    };
}

const mapDispatchToProps = dispatch => (
    {
        joinMeeting: (topic_id) => dispatch(joinMeeting(topic_id)),
    }
)

export default withStyles(styles,
    {
        withTheme: true
    }
)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withSanctum(withRouter(Meeting))));
