import React, {Component} from "react";
import 'fontsource-roboto';
import {fetchMyTopics} from "../../actions";
import {connect} from 'react-redux'
import LogoInit from "../Logo/LogoInit";
import Typography from "@material-ui/core/Typography";
import {Grid, Link} from "@material-ui/core";

class MyConversationsPage extends Component {

    state = {}


    componentDidMount() {
    }

    render() {
        return (
            <Grid container justify={"center"}>
                <Grid item md={7}>
                    <div className='intro_content__wrapper'>
                        <div className='terms__logo-wrap'>
                            <LogoInit variant={'h2'}/>
                        </div>
                        <div className="separator">
                            <Typography variant="h3" gutterBottom className='welcome-title'>
                                C2C Labs Privacy Policy
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                Last Modified: November 2, 2020
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                1. Introduction
                            </Typography>

                            <Typography className='welcome-text' gutterBottom>
                                At C2C Labs, we respect the privacy rights and data protection rights of our users and
                                recognize the importance of protecting the personal information we collect about you. We
                                are committed to protecting your privacy through our compliance with this policy. Our
                                Privacy Policy is designed to help you understand what information we collect and how we
                                use and share that information. This Privacy Policy applies to our Websites and
                                Services.
                                As used in this Privacy Policy, “C2C Labs”, “us”, “our”, or “we” refers to C2C Labs,
                                LLC. C2C Labs is the owner and operator of the following websites and services.
                                <br/>
                                <br/>
                                The “Websites” means SPARCmore.com (including without limitation <Link
                                href={`${process.env.REACT_APP_URL}`} className='links-primary'
                                color={"secondary"}>www.sparcmore.com</Link>, and any successor URLS and
                                related domains and subdomains) and the “Services” means the SPARCmore software platform
                                that includes crowdsourcing technologies, attendee data and analytics, and other
                                services, in each case in whatever format they may be offered in now or in the future.
                                The Websites and Services are collectively referred to herein as the “Offerings.”
                                <br/>
                                <br/>
                                If you do not agree with our policies and practices, you may choose not to use our
                                Offerings.
                                <br/>
                                <br/>
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                <strong>
                                    Classifications of Users
                                </strong>
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                This policy applies to the following classification of users that interact with C2C
                                Labs’ Offerings:
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                <ul>
                                    <li>
                                        <strong>Customers:</strong> Customers are individuals that are employees or
                                        associates of C2C Labs’s direct customers (for example: event planners,
                                        community managers, and marketers), including customer personnel that are
                                        assigned a login ID and are authorized to access and use our Offerings pursuant
                                        to an active C2C Labs agreement, or under a temporary evaluation license, if
                                        granted. Additionally, Customers include individuals who self-register to access
                                        our Offerings, including both Free and Paid users.
                                    </li>
                                    <li>
                                        <strong>Customer Business Contacts:</strong> Customer Business Contacts are
                                        individuals that interact with our Customers through our Offerings. These
                                        include our Customers’ current and prospective clients, members, attendees,
                                        sponsors, exhibitors, marketing partners, or other business contacts. For
                                        example, Customer Business Contacts include individuals that sign into a
                                        Customer’s events and sessions to view, interact, and connect with the Customer
                                        and with other Customer Business Contacts.
                                    </li>
                                    <li>
                                        <strong>Visitors:</strong> Individuals that interact with our Websites
                                        anonymously (for instance, to view our homepage at SPARCmore.com), as well as
                                        those who we learn through a referral from third parties or other external
                                        sources.
                                    </li>
                                </ul>
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                2. Types of information that C2C Labs collect
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                <strong>
                                    A. Information Related to Interactions with C2C Labs and the Offerings.
                                </strong>
                                <br/>
                                <br/>
                                <strong>
                                    Registration and Contact Information
                                </strong>
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                Our Offerings are flexible and allow for the collection of a variety of information
                                about you such as full name, email, company, username, occupation, phone number, social
                                media account ID, and other information including but not limited to interests,
                                opinions, activities, gender, and education (“<strong>Personal Data</strong>”).
                                <br/>
                                <br/>
                                We collect this information from you when you register to use our Offerings as a
                                Customer. Our Customers collect this information from you as Customer Business Contacts
                                when you voluntarily and explicitly enter personal data into our Offerings.
                                <br/>
                                <br/>
                                <Typography className='welcome-text' gutterBottom>
                                    <strong>
                                        Technical, Usage and Location Information.
                                    </strong>
                                </Typography>
                                We automatically collect information on how you interact with our Offerings, such as the
                                IP address from which you access the Offerings, date and time, information about your
                                browser, operating system and computer or device, pages viewed, and items clicked. We
                                may also collect location information, including location information automatically
                                provided by your computer or device. We use cookies and similar technologies to collect
                                some of this information. For more information, please see Blocking Cookies in Section 6
                                (Your Rights and Choices).
                                <br/>
                                <br/>
                                <strong>Third Party Platforms.</strong> We may collect information when you interact
                                with other content on third-party sites or platforms, such as social networking sites.
                                This may include information such as social shares, social “Likes”, profile information
                                or the fact that you viewed or interacted with our content.
                                <br/>
                                <br/>
                                <strong> Other Information.</strong> We may collect other information from you that is
                                not specifically listed here. We may use any such information in accordance with this
                                Privacy Policy or as otherwise permitted by you.
                                <br/>
                                <br/>
                                <strong>Legal Basis.</strong> Our legitimate interest. C2C Labs’ Offerings help our
                                Customers and Customer Business Contacts to better engage, interact, and connect with
                                each other and with Customers’ events. In order to provide this interactive engagement
                                and connection between Customers and Customer Business Contacts, we have a legitimate
                                interest to collect relevant Personal Data. We consider your privacy and data protection
                                rights when we pursue our legitimate interests and ensure that the way our Offerings
                                work don't impact on those rights. For Visitors, C2C Labs will store this data for 9
                                months, for Customers and Customer Business Contacts C2C Labs will store this data until
                                the individual opts out or where they have not engaged with C2C Labs in 24 months.
                                <br/>
                                <br/>
                                <strong>B. Customer Data.</strong>
                                <br/>
                                <br/>
                                As a Customer or Customer Business Contact, you may voluntarily submit various types of
                                information and data into the Offerings for hosting and processing purposes (“<strong>Customer
                                Data</strong>”). Customer Data may include, without limitation, (1) personal information
                                of event and session attendees including names, email address, and social media profile
                                information and (2) information shared between Customer and Customer Business Contacts,
                                and between Customer Business Contacts which may include but not limited to social media
                                connection requests, audio and video chat records, and any content shared on the
                                platform. The Offerings provide options for Customer Business Contacts to share event
                                and sessions info to third-party social networking services. Through APIs of third-party
                                social networking services, we enable Customers to retrieve available information about
                                Customer Business Contacts including name and number of followers/friends.
                                <br/>
                                <br/>
                                When a Customer Business Contact chooses to use our Offerings to interact with a
                                Customer (for example: to sign up for and participate in an event and subsequent live
                                sessions), any Customer Data provided in connection with that interaction will be under
                                the control of the Customer. Customer will also have access to information (including
                                personal data and the Offerings usage data) related to how Customer Business Contact
                                interacts with the Offerings they use. In such instances, the Customer acts as a data
                                controller towards the Customer Business Contact, under the European Economic Area
                                (“EEA”) data protection laws. Therefore, C2C Labs cannot and does not take
                                responsibility for the privacy practices of Customers. The information practices of our
                                Customers are governed by their privacy policies. We encourage Customer Business
                                Contacts to review the Customers’ privacy policies to understand their practices and
                                procedures.
                                <br/>
                                <br/>
                                C2C Labs does not use Customer Data of our Customer Business Contacts for any purpose
                                other than to provide services that our Customers have contracted us to provide through
                                our Offerings (“<strong>Customer Agreement</strong>”) or as required by law.
                                <br/>
                                <br/>
                                <strong>Legal Basis.</strong> We process and store Customer Data to perform our Customer
                                Agreement with our Customers. Without this information, we wouldn't be able to provide
                                our Offerings to our Customers and Customer Customer Business Contacts. We also pursue
                                our legitimate interests by ensuring the smooth running of our Customer Agreement.
                                Without this information, we wouldn't be able to provide our Offerings to our Customer
                                and Customer Business Contacts. We consider your privacy and data protection rights when
                                we pursue our legitimate interests and ensure that the way our Offerings work don't
                                impact on those rights.
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                3. How We Use the Information We Collect
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                We use your information in the following ways:
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                <ul>
                                    <li>
                                        To disclose to contractors, service providers, and other third parties as
                                        reasonably necessary or prudent to provide, maintain and support our Offerings,
                                        such as, for example, payment processors and data center or Web hosting
                                        providers. <strong> C2C Labs does not share, sell or trade any information with
                                        such third parties for promotional purposes.</strong>
                                    </li>
                                    <li>
                                        To deliver the Offerings that our Customer has contracted us to provide. Some
                                        examples include:
                                        <ul>
                                            <li>
                                                If a Customer Business Contact uses one of our Offerings to participate
                                                in a session on their device and interact with other attendees, we will
                                                provide the Customer the Personal Data and Customer Data of the Customer
                                                Business Contact.
                                            </li>
                                            <li>
                                                When a Customer or Customer Business Contact uses their social media
                                                credentials to share information onto their social media accounts or to
                                                log into one of our Offerings, we will share information with their
                                                social media account provider. The information we share will be governed
                                                by the social media site’s privacy policy.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        To provide, maintain and improve the Offerings and our other products and
                                        services, including to operate certain features and functionality of the
                                        Offerings (for example, by remembering your information so that you will not
                                        have to re-enter it during this or subsequent visits).
                                    </li>
                                    <li>
                                        To process your inquiries and otherwise deliver customer service;
                                    </li>
                                    <li>
                                        To process your payments, we share and use Payment Information as described in
                                        Section 4 (Payment Information);
                                    </li>
                                    <li>
                                        To control unauthorized use or abuse of the Offerings and our other products and
                                        services, or otherwise detect, investigate or prevent activities that may
                                        violate our policies or be illegal;
                                    </li>
                                    <li>
                                        To analyze trends, administer or optimize the Offerings, monitor usage or
                                        traffic patterns (including to track users’ movements around the Offerings) and
                                        gather demographic information about our user base as a whole;
                                    </li>
                                    <li>
                                        To communicate directly with you, including by sending you newsletters,
                                        promotions and special offers or information about new products and services.
                                        Your opt-out options for promotional communications are described in Section 6
                                        (Your Rights and Choices); and
                                    </li>
                                    <li>
                                        In the manner described to you at the time of collection or as otherwise
                                        described in this Privacy Policy.
                                    </li>
                                    <li>
                                        To deliver to a third party in the event of a merger, divestiture,
                                        restructuring, recapitalization, reorganization, dissolution or other sale or
                                        transfer of some or all C2C Lab’s assets, whether as a continuing operating
                                        business or as part of bankruptcy, liquidation or similar proceeding, in which
                                        personal data held by C2C Labs about our Customers and Customer Business
                                        Contacts is among the assets transferred.
                                    </li>
                                    <li>
                                        As we believe to be necessary or appropriate: (a) under applicable law,
                                        including laws outside your country of residence; (b) to respond to requests
                                        from public and government authorities including public and government
                                        authorities outside your country of residence; and (c) to protect against or
                                        identify fraudulent transactions.
                                    </li>

                                </ul>
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                4. Payment Information
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                When you make a purchase on the Offerings, any credit card information you provide as
                                part of your Payment Information is collected and processed directly by our payment
                                processor Stripe through their Stripe Checkout service. We never receive or store your
                                full credit card information. Stripe commits to complying with the Payment Card Industry
                                Data Security Standard (PCI-DSS) and using industry standard security. Stripe may use
                                your Payment Information in accordance with their own Privacy Policy here:
                                <Link href="https://stripe.com/us/checkout/legal" className='links-primary'
                                      color={"secondary"}> https://stripe.com/us/checkout/legal</Link>.
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                5. Other Access to or Disclosure of Your Information
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                The Offerings may also contain links to third party websites (for example social media
                                services for sharing of slides). This Privacy Policy applies solely to information
                                collected by us. Even if the third party is affiliated with us through a business
                                partnership or otherwise, we are not responsible for the privacy practices of such third
                                party. We encourage you to familiarize yourself with the privacy policies of such third
                                parties to determine how they handle any information they separately collect from you.
                                Please be aware that we do not warn you when you choose to click through to another
                                website when using the Offerings.
                                <br/>
                                <br/>
                                The Offerings contain features that enable you to post comments in chat, your
                                submissions for topics of discussions, and your audio and video stream during a session
                                which are all publicly viewable. You should be aware that any personal information you
                                submit as part of those posts can be read, collected, or used by other visitors to
                                Offerings, and could be used to send you unsolicited messages. We are not responsible
                                for the personal information you choose to publicly post on the Offerings.
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                6. Your Rights and Choices
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                <strong>Opt-Outs.</strong> We may provide you with the opportunity to “opt-out” of
                                having your personal information used for certain purposes when we ask for this
                                information. If you decide to opt-out, we may not be able to provide certain features of
                                the Offerings to you.
                                <br/>
                                <br/>
                                <strong>Communication Preferences.</strong> If you no longer wish to receive our
                                newsletter and promotional communications, you may opt-out of receiving them by
                                following the instructions included on such communications or on the Offerings. Please
                                note, however, that you may be unable to opt-out of certain service-related
                                communications.
                                <br/>
                                <br/>
                                <strong>Blocking Cookies.</strong> You can remove or block certain cookies using the
                                settings in your browser but the Offerings may cease to function properly if you do so.
                                <strong>How We Respond to Do Not Track Signals.</strong> Your Web browser may have a “do
                                not track” setting which, when enabled, causes your browser to send a do not track HTTP
                                header file or “signal” to each site you visit. At present, the Offerings do not respond
                                to this type of signal.
                                <strong>Data Subject Rights.</strong> You can access, rectify, erase, restrict or export
                                your personal information at any time by emailing us at team@sparcmore.com. You can
                                object to our processing of your personal information at any time. Contact us with
                                requests or concerns at privacy@sparcmore.com. If you are unsatisfied with the response,
                                you have the right to lodge a complaint with your supervisory authority.
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                7. Accessing and Updating Your Personal Information
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                When you use the Offerings, we make good faith efforts to provide you with access to
                                your personal information upon your request and either provide you the means to correct
                                this information if it is inaccurate or to delete such information at your request if it
                                is not otherwise required to be retained by law or for legitimate business purposes. You
                                may access, review, correct, update, change or delete your information at any time upon
                                satisfactory verification of your identity. To do so, please contact us at
                                team@sparcmore.com with your name and the information requested to be accessed,
                                corrected or removed, or if you are a Customer, sign in to your account, go to your
                                profile, and make the desired changes. If you are a Customer Business Contact, your
                                personal information is controlled by the Customer and your request will be forwarded to
                                the Customer for review and processing. We may decline to process requests that are
                                unreasonably repetitive or systematic, require disproportionate technical effort (for
                                instance, requests concerning information residing on backup systems), jeopardize the
                                privacy of others, would be extremely impractical, or for which access is not otherwise
                                required. In any case where we provide information access and correction, we perform
                                this service free of charge, except if doing so would require a disproportionate effort.
                                <br/>
                                <br/>
                                Please note that if you cease using the Service or we terminate your access to the
                                Service in accordance with your Customer Agreement, you may no longer have the ability
                                to access or update your information.
                                <br/>
                                <br/>
                                We may retain your information as necessary to support the Offerings, comply with our
                                legal obligations or resolve disputes. Note that content you post may remain on the
                                Offerings even if you cease using the Offerings or we terminate your access to the
                                Offerings.
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                8. Changes to the Privacy Policy
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                We reserve the right to change our Privacy Policy at any time. If we make changes, we
                                will post them and will indicate on this page the policy’s new effective date. If we
                                make material changes to this policy, we will notify you by email or through notice on
                                the Offerings.
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h4" className='welcome-title' gutterBottom>
                                9. Security
                            </Typography>
                            <Typography className='welcome-text' gutterBottom>
                                The security of your personal information is important to us. We maintain a variety of
                                appropriate technical and organizational safeguards to protect your personal
                                information. We limit access to personal information about you to employees who we
                                believe reasonably need to come into contact with that information to provide products
                                or services to you or in order to do their jobs. Further, we have implemented reasonable
                                physical, electronic, and procedural safeguards designed to protect personal information
                                about you. When you enter sensitive information (such as your password), we encrypt that
                                information in transit using industry-standard Transport Layer Security (TLS) encryption
                                technology. No method of transmission over the Internet, method of electronic storage or
                                other security methods are one hundred percent secure. Therefore, while we strive to use
                                reasonable efforts to protect your personal information, we cannot guarantee its
                                absolute security.
                                <br/>
                                <br/>
                                <strong>
                                    International Transfer of Data
                                </strong>
                                <br/>
                                <br/>
                                We may from time to time transfer your personal information to other countries outside
                                the United States and make it accessible to our third party service providers
                                internationally. We will protect your personal information in accordance with this
                                Privacy Policy wherever it is processed. Personal Data and Customer Data may be accessed
                                by employees of IT service providers with whom we have engaged. In such instances, we
                                use Standard Contract Clauses to protect personal data. If you would like a copy or have
                                other queries, please contact us at privacy@sparcmore.com.
                                <br/>
                                <br/>
                                If you have questions or need to contact us about this Privacy Policy, please email us
                                at privacy@sparcmore.com
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }

}

const mapStateToProps = (state) => {
    return {};
}

export default connect(
    mapStateToProps,
    {}
)(MyConversationsPage);
