import {
    FETCH_SET_TOPIC_MODAL,
    CLOSE_SET_TOPIC_MODAL,
    FETCH_NOT_FOUND_MODAL,
    CLOSE_NOT_FOUND_MODAL,
    FETCH_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    FETCH_TOPIC_FEEDBACK_MODAL,
    CLOSE_TOPIC_FEEDBACK_MODAL,
    FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL,
    CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL, THEME
} from '../actions/types'


export default (state = {}, action) => {
    // console.log(action);
    switch (action.type) {
        case THEME:
            return {...state, data: action.payload}
        default:
            return state;
    }
}
