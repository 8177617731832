import {
    DRAWER_OPEN,
    DRAWER_CLOSE
} from '../actions/types'


let INITIAL_STATE = {
    drawerIsOpen: true
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DRAWER_OPEN:
            return {...state, type: action.data, drawerIsOpen: true}
        case DRAWER_CLOSE:
            return {...state, type: action.data, drawerIsOpen: false}
        default:
            return state;
    }
}
