import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    Checkbox,
    DialogContent, Divider, FormControlLabel, FormGroup, Grid, Link, ListItem, ListItemAvatar, ListItemText, TextField
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal, flag, showModal} from "../../actions";
import Typography from "@material-ui/core/Typography";
import LogoInit from "../Logo/LogoInit";
import {blue} from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import {DateTime} from "luxon";
import {convertDate} from "../../reducers/TopicsReducer";

const styles = theme => ({
    buttonSubmit: {
        margin: '0.313rem',
        padding: '0.313rem 1.563rem',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.link.main,
        '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.link.main,
        }
    },
    buttonCancel: {
        margin: '0.313rem',
        padding: '0.313rem 1.563rem',
    },
    shanoAvatar: {
        marginTop: '0px',
        minWidth: '40px'
    },
    paragraphWrap: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    flsgListWrap: {
        height: '50vh',
        overflow: 'scroll',
    },
    paragraph1: {
        width: '30%',
    },
    paragraph2: {
        width: '70%',
        padding: '0 0 0 35px',
        textAlign: 'right'
    }
});


class ModalShowFlags extends Component {

    state = {
        modalTopic: this.props.modal.data.content,
        modalIsOpen: false,
    }

    componentDidMount() {
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-show-flags')
    };

    deleteTopic = (type, data) => {
        this.props.showModal(type, data)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__topic-feedback'>
                    <Typography variant='h6' style={{textAlign: 'left', marginTop: '15px'}}>
                        User:
                    </Typography>
                    <Typography variant='h5' style={{textAlign: 'left', marginTop: '10px'}}>
                        {this.state.modalTopic.host ? this.state.modalTopic.host.name : this.state.modalTopic.name}
                    </Typography>
                    <br/>
                    <Divider/>
                    <div className={classes.flsgListWrap}>
                        <List>
                            {
                                this.state.modalTopic.flags.map((flag, index) => {

                                    return (
                                        <div key={index}>
                                            <ListItem alignItems="flex-start" style={{marginTop: '30px', marginBottom: '30px'}}>
                                                <ListItemAvatar className={classes.shanoAvatar}>
                                                    <span>
                                                        {index + 1}
                                                    </span>
                                                </ListItemAvatar>
                                                <div style={{width: '100%'}}>
                                                    <div style={{marginTop: '0px'}} className={classes.paragraphWrap}>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph1}
                                                        >
                                                            Date of report:
                                                        </Typography>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph2}
                                                        >
                                                            {convertDate(flag.created_at)}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.paragraphWrap}>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph1}
                                                        >
                                                            Reported by user:
                                                        </Typography>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph2}
                                                        >
                                                            {flag.created_by}
                                                        </Typography>
                                                    </div>
                                                    {
                                                        this.state.modalTopic.title
                                                        ?
                                                            <div className={classes.paragraphWrap}>
                                                                <Typography
                                                                    component="p"
                                                                    variant="body1"
                                                                    color="textPrimary"
                                                                    className={classes.paragraph1}
                                                                >
                                                                    Conversation room:
                                                                </Typography>
                                                                <Typography
                                                                    component="p"
                                                                    variant="body1"
                                                                    color="textPrimary"
                                                                    className={classes.paragraph2}
                                                                >
                                                                    {this.state.modalTopic.title}
                                                                </Typography>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    <div className={classes.paragraphWrap}>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph1}
                                                        >
                                                            Reported violations:
                                                        </Typography>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph2}
                                                        >
                                                            {flag.type}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.paragraphWrap}>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph1}
                                                        >
                                                            Additional note:
                                                        </Typography>
                                                        <Typography
                                                            component="p"
                                                            variant="body1"
                                                            color="textPrimary"
                                                            className={classes.paragraph2}
                                                        >
                                                            {flag.comment}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </ListItem>
                                            <Divider variant="inset" component="li"/>
                                        </div>
                                    )
                                })
                            }
                        </List>
                    </div>

                    <div className='modal__topic-feedback__actions'>

                        <Button
                            variant="contained"
                            color='primary'
                            className={classes.buttonCancel}
                            onClick={this.closeModal}>
                            Cancel
                        </Button>
                    </div>

                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => (
    {
        closeModal: data => dispatch(closeModal(data)),
        showModal: (type, data) => dispatch(showModal(type, data)),
    }
)

export default withRouter(withStyles(styles,
    {
        withTheme: true
    }
)(connect(mapStateToProps, mapDispatchToProps)(ModalShowFlags)));
