import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateBranding } from "../../actions";
import { FormControlLabel, RadioGroup, Radio, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Box, TextField, Fab, Hidden } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import Branding from "../branding/Branding";
import { CompactPicker } from 'react-color'
import { findAllInRenderedTree } from "react-dom/test-utils";

const styles = theme => ({
    buttonPrimary: {
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[700],
        }
    },
    inputImage: {
        display: 'none'
    },
    buttonImage: {
        cursor: 'pointer',
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
    fabButtonImage: {
        display: 'block',
        width: 'auto',
        height: 'auto',
        borderRadius: '10px',
        marginRight: '5px'
    }
})

class BrandingPage extends Component {

    state = {
        uuid: this.props.conference.data.uuid,
        // Color Pickers
        displayHCP: false,
        displayHTCP: false,
        displayBCP: false,
        // Logo
        branding_logo_image_size: 2,
        branding_logo_image_remove: false,
        branding_logo_image_grater: true,
        branding_logo_image: '',
        branding_logo_url: '',
        // Header
        header_type: 'color',
        branding_header_image_size: 5,
        branding_header_image_remove: false,
        branding_header_image_grater: true,
        branding_header_image: '',
        branding_header_color: '#1E1E1E',
        branding_header_text_color: '#FFFFFF',
        // Background
        background_type: 'color',
        branding_background_image_size: 10,
        branding_background_image_remove: false,
        branding_background_image_grater: true,
        branding_background_image: '',
        branding_background_color: '#141414'
    }

    componentDidMount() {
        this.checkBranding()
    }

    checkBranding = () => {
        if (this.props.conference.data.branding) {
            this.setState({
                // Logo
                branding_logo_image: this.props.conference.data.branding.logo.image,
                branding_logo_url: this.props.conference.data.branding.logo.url || '',
                // Header
                header_type: this.props.conference.data.branding.header.background.type,
                branding_header_image: this.props.conference.data.branding.header.background.data,
                branding_header_image_remove: this.props.conference.data.branding.header.background.type === 'image',
                branding_header_color: this.props.conference.data.branding.header.background.type === 'color' ? this.props.conference.data.branding.header.background.data : this.state.branding_header_color,
                branding_header_text_color: this.props.conference.data.branding.header.color,
                // Background
                background_type: this.props.conference.data.branding.background.type,
                branding_background_image: this.props.conference.data.branding.background.data,
                branding_background_image_remove: this.props.conference.data.branding.background.type === 'image',
                branding_background_color: this.props.conference.data.branding.background.type === 'color' ? this.props.conference.data.branding.background.data : this.state.branding_background_color
            })
        }
    }

    handleUpdateUrl = (e) => {
        this.setState({ branding_logo_url: e.target.value })
    }

    handleBackgroundType = (e) => {
        this.setState({ background_type: e.target.value })
    }

    handleHeaderType = (e) => {
        this.setState({ header_type: e.target.value })
    }

    handleRemoveLogoImage = () => {
        this.setState({
            branding_logo_image_remove: true,
            branding_logo_image: '',
            branding_logo_image_file: '',
            branding_logo_image_grater: true
        })
    }

    handleRemoveHeaderImage = () => {
        this.setState({
            branding_header_image_remove: false,
            branding_header_image: '',
            branding_header_image_file: '',
            branding_header_image_grater: true,
            header_type: 'color',
        })
    }

    handleRemoveBackgroundImage = () => {
        this.setState({
            branding_background_image_remove: false,
            branding_background_image: '',
            branding_background_image_file: '',
            branding_background_image_grater: true,
            background_type: 'color',
        })
    }

    handleFile = (e, field) => {
        if (e.target.files[0]) {
            if (field === 'branding_logo_image') {
                const file = e.target.files[0];
                const fileSize = e.target.files[0].size;
                if (parseFloat(fileSize / (1024 * 1024)).toFixed(2) <= this.state.branding_logo_image_size) {
                    this.setState({
                        branding_logo_image: URL.createObjectURL(file),
                        branding_logo_image_file: file,
                        branding_logo_image_grater: true
                    });
                } else {
                    this.setState({
                        branding_logo_image: '',
                        branding_logo_image_file: '',
                        branding_logo_image_grater: false
                    });
                }
            } else if (field === 'branding_header_image') {
                const file = e.target.files[0];
                const fileSize = e.target.files[0].size;
                if (parseFloat(fileSize / (1024 * 1024)).toFixed(2) <= this.state.branding_header_image_size) {
                    this.setState({
                        branding_header_image: URL.createObjectURL(file),
                        branding_header_image_file: file,
                        branding_header_image_remove: true,
                        branding_header_image_grater: true
                    });
                } else {
                    this.setState({
                        branding_header_image: '',
                        branding_header_image_file: '',
                        branding_header_image_remove: false,
                        branding_header_image_grater: false
                    });
                }
            } else if (field === 'branding_background_image') {
                const file = e.target.files[0];
                const fileSize = e.target.files[0].size;
                if (parseFloat(fileSize / (1024 * 1024)).toFixed(2) <= this.state.branding_background_image_size) {
                    this.setState({
                        branding_background_image: URL.createObjectURL(file),
                        branding_background_image_file: file,
                        branding_background_image_remove: true,
                        branding_background_image_grater: true
                    });
                } else {
                    this.setState({
                        branding_background_image: '',
                        branding_background_image_file: '',
                        branding_background_image_remove: false,
                        branding_background_image_grater: false
                    });
                }
            }
        } else {
            return null;
        }
    }

    handleClickColorPicker = (e, field) => {
        if (field === 'branding_header_color') {
            this.setState({ displayHCP: !this.state.displayHCP })
        } else if (field === 'branding_background_color') {
            this.setState({ displayBCP: !this.state.displayBCP })
        } else if (field === 'branding_header_text_color') {
            this.setState({ displayHTCP: !this.state.displayHTCP })
        }
    }

    handleCloseColorPicker = (e, field) => {
        if (field === 'branding_header_color') {
            this.setState({ displayHCP: false })
        } else if (field === 'branding_background_color') {
            this.setState({ displayBCP: false })
        } else if (field === 'branding_header_text_color') {
            this.setState({ displayHTCP: false })
        }
    }

    handleChangeColorPicker = (color, field) => {
        if (field === 'branding_header_color') {
            this.setState({ branding_header_color: color.hex });
        } else if (field === 'branding_background_color') {
            this.setState({ branding_background_color: color.hex });
        } else if (field === 'branding_header_text_color') {
            this.setState({ branding_header_text_color: color.hex });
        }
    }

    submitBranding = () => {
        let formData = new FormData();
        let sendState = { ...this.state }
        for (const property in sendState) {
            if (property) {
                if (property === "branding_logo_image_file") {
                    if (this.state.branding_logo_image_file) {
                        formData.append('logo[image]', this.state.branding_logo_image_file)
                    }
                } else if (property === "branding_logo_image_remove") {
                    if (this.state.branding_logo_image_remove) {
                        formData.append('logo[delete]', 1)
                    }
                } else if (property === "branding_header_image_file") {
                    if (this.state.branding_header_image_file) {
                        formData.append('header[background][image]', this.state.branding_header_image_file)
                    }
                } else if (property === "branding_background_image_file") {
                    if (this.state.branding_background_image_file) {
                        formData.append('background[image]', this.state.branding_background_image_file)
                    }
                } else if (property === "branding_logo_url") {
                    formData.append('logo[url]', this.state.branding_logo_url)
                } else if (property === "branding_header_text_color") {
                    formData.append('header[color]', this.state.branding_header_text_color)
                } else if (property === "branding_header_color") {
                    formData.append('header[background][color]', this.state.branding_header_color)
                } else if (property === "branding_background_color") {
                    formData.append('background[color]', this.state.branding_background_color)
                } else if (property === "header_type") {
                    formData.append('header[background][type]', this.state.header_type)
                } else if (property === "background_type") {
                    formData.append('background[type]', this.state.background_type)
                }
            }
        }
        formData.append('_method', 'POST')
        this.props.updateBranding(formData, this.state.uuid)
            .then(() => {
                window.location.reload();
            })
    }

    clearBranding = () => {
        this.setState({
            branding_logo_image_remove: true,
            branding_logo_image: '',
            branding_logo_image_file: '',
            branding_logo_image_grater: true,
            branding_logo_url: '',
            header_type: 'color',
            branding_header_image_grater: true,
            branding_header_image_remove: false,
            branding_header_image: '',
            branding_header_color: '#1E1E1E',
            branding_header_text_color: '#FFFFFF',
            background_type: 'color',
            branding_background_image_grater: true,
            branding_background_image_remove: false,
            branding_background_image: '',
            branding_background_color: '#141414'
        })
    }

    renderHeaderType() {
        const { classes } = this.props;
        if (this.state.header_type == 'color') {
            return (
                <div className="color__picker">
                    <Box mr={1} fontWeight="fontWeightBold">Pick your color:</Box>
                    <div className="color__picker__btn" onClick={(e) => { this.handleClickColorPicker(e, 'branding_header_color') }}>
                        <div style={{ background: this.state.branding_header_color }} />
                    </div>
                    {
                        this.state.displayHCP ? <div className="color__picker__popover">
                            <div className="color__picker__cover" onClick={(e) => { this.handleCloseColorPicker(e, 'branding_header_color') }} />
                            <CompactPicker color={this.state.branding_header_color} onChange={(e) => { this.handleChangeColorPicker(e, 'branding_header_color') }} />
                        </div> : null
                    }
                </div>
            );
        } else {
            return (
                <div className="d-flex align-center">
                    <div className="d-flex align-center">
                        <input accept="image/*" className={classes.inputImage} id='brandingHeader' type="file" required name='file' onChange={(e) => this.handleFile(e, 'branding_header_image')} />
                        <Fab color="primary" aria-label="add" className={classes.fabButtonImage}>
                            <label htmlFor="brandingHeader" className={classes.buttonImage}>choose image</label>
                        </Fab>
                        {this.state.branding_header_image_remove &&
                            <Button variant="contained" color="secondary" startIcon={<ClearIcon />} onClick={() => { this.handleRemoveHeaderImage() }}>remove</Button>
                        }
                    </div>
                    <Typography variant="caption" className={'MuiFormHelperText-root'} style={{ marginLeft: 10, lineHeight: 1, fontSize: 13 }}>
                        <p style={{ color: this.state.branding_header_image_grater ? 'inherit' : 'red' }}>File size limit: <b>5MB</b></p>
                        <p>Max dimensions: <b>2880 x 300</b></p>
                    </Typography>
                </div>
            );
        }
    }

    renderBackgroundType() {
        const { classes } = this.props;
        if (this.state.background_type == 'color') {
            return (
                <div className="color__picker">
                    <Box mr={1} fontWeight="fontWeightBold">Pick your color:</Box>
                    <div className="color__picker__btn" onClick={(e) => { this.handleClickColorPicker(e, 'branding_background_color') }}>
                        <div style={{ background: this.state.branding_background_color }} />
                    </div>
                    {
                        this.state.displayBCP ? <div className="color__picker__popover">
                            <div className="color__picker__cover" onClick={(e) => { this.handleCloseColorPicker(e, 'branding_background_color') }} />
                            <CompactPicker color={this.state.branding_background_color} onChange={(e) => { this.handleChangeColorPicker(e, 'branding_background_color') }} />
                        </div> : null
                    }
                </div>
            );
        } else {
            return (
                <div className="d-flex align-center">
                    <div className="d-flex align-center">
                        <input accept="image/*" className={classes.inputImage} id='brandingBackground' type="file" required name='file' onChange={(e) => this.handleFile(e, 'branding_background_image')} />
                        <Fab color="primary" aria-label="add" className={classes.fabButtonImage}>
                            <label htmlFor="brandingBackground" className={classes.buttonImage}>choose image</label>
                        </Fab>
                        {this.state.branding_background_image_remove &&
                            <Button variant="contained" color="secondary" startIcon={<ClearIcon />} onClick={() => { this.handleRemoveBackgroundImage() }}>remove</Button>
                        }
                    </div>
                    <Typography variant="caption" className={'MuiFormHelperText-root'} style={{ marginLeft: 10, lineHeight: 1, fontSize: 13 }}>
                        <p style={{ color: this.state.branding_background_image_grater ? 'inherit' : 'red' }}>File size limit: <b>10MB</b></p>
                        <p>Max dimensions: <b>2880 x 1800</b></p>
                    </Typography>
                </div>
            );
        }
    }

    IsFormValid = () => {
        return this.state.background_type === 'image' && !this.state.branding_background_image_remove || this.state.header_type === 'image' && !this.state.branding_header_image_remove;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className='branding__wrap'>
                <Grid container spacing={4}>
                    <Grid item md={4} lg={4}>
                        <form noValidate autoComplete="off">
                            <Box mb={3}>
                                <Typography variant="h5">Customize the look and feel of your event page and presentation/session below</Typography>
                            </Box>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Your Logo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="d-flex align-center">
                                            <div className="d-flex align-center">
                                                <input accept="image/*" className={classes.inputImage} id='brandingLogo' type="file" required name='file' onChange={(e) => this.handleFile(e, 'branding_logo_image')} />
                                                <Fab color="primary" aria-label="add" className={classes.fabButtonImage}>
                                                    <label htmlFor="brandingLogo" className={classes.buttonImage}>choose image</label>
                                                </Fab>
                                                {this.state.branding_logo_image &&
                                                    <Button variant="contained" color="secondary" startIcon={<ClearIcon />} onClick={() => { this.handleRemoveLogoImage() }}>remove</Button>
                                                }
                                            </div>
                                            <Typography variant="caption" className={'MuiFormHelperText-root'} style={{ marginLeft: 10, lineHeight: 1, fontSize: 13 }}>
                                                <p style={{ color: this.state.branding_logo_image_grater ? 'inherit' : 'red' }}>File size limit: <b>2MB</b></p>
                                                <p>Max dimensions: <b>190 x 55</b></p>
                                                <p>Recommended format: <b>PNG</b></p>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={this.state.branding_logo_url} onChange={(e) => { this.handleUpdateUrl(e) }} label="Your Website" fullWidth variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Header</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className="color__picker">
                                                <Box mr={1} fontWeight="fontWeightBold">Title color:</Box>
                                                <div className="color__picker__btn" onClick={(e) => { this.handleClickColorPicker(e, 'branding_header_text_color') }}>
                                                    <div style={{ background: this.state.branding_header_text_color }} />
                                                </div>
                                                {
                                                    this.state.displayHTCP ? <div className="color__picker__popover">
                                                        <div className="color__picker__cover" onClick={(e) => { this.handleCloseColorPicker(e, 'branding_header_text_color') }} />
                                                        <CompactPicker color={this.state.branding_header_text_color} onChange={(e) => { this.handleChangeColorPicker(e, 'branding_header_text_color') }} />
                                                    </div> : null
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup row aria-label="header_type" name="header_type" defaultValue="top">
                                                <FormControlLabel value="color" onChange={(e) => { this.handleHeaderType(e) }} checked={this.state.header_type === "color"} control={<Radio color="primary" />} label="Color" />
                                                <FormControlLabel value="image" onChange={(e) => { this.handleHeaderType(e) }} checked={this.state.header_type === "image"} control={<Radio color="primary" />} label="Image" />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderHeaderType()}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Background</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <RadioGroup row aria-label="background_type" name="background_type" defaultValue="top">
                                                <FormControlLabel value="color" onChange={(e) => { this.handleBackgroundType(e) }} checked={this.state.background_type === "color"} control={<Radio color="primary" />} label="Color" />
                                                <FormControlLabel value="image" onChange={(e) => { this.handleBackgroundType(e) }} checked={this.state.background_type === "image"} control={<Radio color="primary" />} label="Image" />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderBackgroundType()}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Grid container space={3} style={{ marginTop: 15 }}>
                                <Grid item xs={12} className='d-flex justify-between'>
                                    <Button variant="contained" className={classes.buttonPrimary} onClick={() => { this.submitBranding() }} disabled={this.IsFormValid()}>update</Button>
                                    <Button onClick={() => { this.clearBranding() }}><ClearIcon style={{ fontSize: 17, marginRight: 5 }} /> clear all</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item md={8} lg={8}>
                        <Branding data={this.state} />
                    </Grid>
                </Grid>
            </div >
        );
    }

}

const mapStateToProps = (state) => {
    return {
        conference: state.conferences
    };
}

const mapDispatchToProps = dispatch => ({
    updateBranding: (data, uuid) => dispatch(updateBranding(data, uuid))
})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(BrandingPage)));