import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";

const styles = theme => ({});


class LogoInitAlien extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            conferences: null,
            pageUrl: '#',
            customUrl: false
        };
    }

    state = {
        // variant: this.props.variant
    }

    componentDidMount() {

    }

    componentDidUpdate() {
        if (this.props.conferences?.data && this.props.conferences?.data.uuid !== this.state.uuid) {
            this.setState({
                conferences: this.props.conferences.data,
                uuid: this.props.conferences.data.uuid,
                pageUrl: '/' + (this.props.conferences.data.type === 'event' ? 'conference' : this.props.conferences.data.type) + '/' + this.props.conferences.data.uuid,
                customUrl: false
            })
            if (this.props.conferences?.data.branding && this.props.conferences.data.branding.logo.url) {
                this.setState({
                    pageUrl: this.props.conferences.data.branding.logo.url,
                    customUrl: true
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {/*ANOTHER LOGO*/}
                <Typography className={`header__logo__wrap`} variant={this.props.variant} noWrap style={{ visibility: this.props.type == 'sponsor' ? 'hidden' : 'visible' }}>
                    <span className={`header__logo-img__wrap ${this.props.variant}`}>
                        <a href={this.state.pageUrl} target={this.state.customUrl ? '_blank' : '_self'}>
                            {
                                this.props.img
                                    ?
                                    <img src={this.props.img} alt="" />
                                    :
                                    ''
                            }
                        </a>
                    </span>
                </Typography>
                {/* !ANOTHER LOGO*/}
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        conferences: state.conferences
    }
}

const mapDispatchToProps = dispatch => ({})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(LogoInitAlien)));
