import React, { Component } from "react";
import 'fontsource-roboto';
import { AppBar, Avatar, Box, Button, Fab, Grid, InputAdornment, Link, Tab, Tabs, TextField } from "@material-ui/core";
import avatarMan from "./../../assets/images/avatar-man.png"
import logoPlaceholder from "./../../assets/images/logo-placeholder.png"
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchTimeZones, updateProfile } from "../../actions";
import { Autocomplete } from "@material-ui/lab";
import { blue } from "@material-ui/core/colors";
import * as PropTypes from "prop-types";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import EmailIcon from '@material-ui/icons/Email';
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    link: {
        color: theme.palette.link.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    buttonPrimary: {
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[700],
        }
    },
    inputImageUser: {
        display: 'none'
    },
    buttonImageUser: {
        cursor: 'pointer',
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
    fabButtonImageUser: {
        margin: '0 auto',
        display: 'block',
        width: 'auto',
        height: 'auto',
        marginTop: '30px',
        borderRadius: '5px'
    }
})

const LinkTab = (props) => {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class ProfilePage extends Component {

    state = {
        //tz returns list of timezones and the time zone of the device
        tz: null,
        currentTimeZone: null,
        name: '',
        email: '',
        user_info: '',
        title_and_department: '',
        company_name: '',
        company_address: '',
        company_locality: '',
        company_info: '',
        company_website: '',
        linkedin: '',
        twitter: '',
        instagram: '',
        facebook: '',
        youtube: '',
        avatar: '',
        company_logo: '',
        timezone: '',
        isProfileDataChanged: false,
        setUpdate: false,
        tabValue: 0,
        file: null,
        uploadedFileText: '',
        showRemoveBtn: true,
        file_avatar_size: null,
        file_company_logo_size: null,
        maxImageSize: 10,
        isAvatarSizeGrater: false,
        isCompanyLogoSizeGrater: false
    }

    componentDidMount() {
        this.setUserInformation()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.setUserInformation()

    }



    setUserInformation = () => {
        if (this.props.userData?.data.name !== this.state.name) {
            this.props.fetchTimeZones()
                .then(() => {
                    this.setState({
                        name: this.props.userData.data.name ? this.props.userData.data.name : '',
                        email: this.props.userData.data.email ? this.props.userData.data.email : '',
                        avatar: this.props.userData.data.avatar ? this.props.userData.data.avatar : avatarMan,
                        linkedin_url: this.props.userData.data.linkedin_url ? this.props.userData.data.linkedin_url : '',
                        user_info: this.props.userData.data.user_info ? this.props.userData.data.user_info : '',
                        title_and_department: this.props.userData.data.title_and_department ? this.props.userData.data.title_and_department : '',
                        company_name: this.props.userData.data.company_name ? this.props.userData.data.company_name : '',
                        company_address: this.props.userData.data.company_address ? this.props.userData.data.company_address : '',
                        company_locality: this.props.userData.data.company_locality ? this.props.userData.data.company_locality : '',
                        company_info: this.props.userData.data.company_info ? this.props.userData.data.company_info : '',
                        company_website: this.props.userData.data.company_website ? this.props.userData.data.company_website : '',
                        linkedin: this.props.userData.data.linkedin ? this.props.userData.data.linkedin : '',
                        twitter: this.props.userData.data.twitter ? this.props.userData.data.twitter : '',
                        instagram: this.props.userData.data.instagram ? this.props.userData.data.instagram : '',
                        facebook: this.props.userData.data.facebook ? this.props.userData.data.facebook : '',
                        youtube: this.props.userData.data.youtube ? this.props.userData.data.youtube : '',
                        company_logo: this.props.userData.data.company_logo ? this.props.userData.data.company_logo : logoPlaceholder,
                        tz: this.props.tz.options
                    })
                })
                .then(() => {
                    if (!this.state.timezone) {
                        this.setState({
                            timezone: this.setTimeZoneOfTheUser(),
                        })
                    }
                })
        }
    }

    setTimeZoneOfTheUser = () => {

        if (this.props.userData.data.timezone) {
            return this.props.tz.options.find(tz => tz.tz === this.props.userData.data.timezone)

        } else if (!this.props.userData.data.timezone && this.props?.tz !== this.state.tz) {
            return this.props.tz.currentTimeZone
        }
    }

    updateTimeZone = (data) => {
        this.setState({
            timezone: data
        })
    }

    updateLI = (e) => {
        this.setState({
            linkedin: e.target.value
        })
    }

    submitProfileData = () => {
        let formData = new FormData();
        let sendState = { ...this.state }
        // ITERATE OVER THE STATE AND APPEND THE FIELDS TO FORMdATA
        //CHECK FOR SAME PROPERTIES IF ARE CHANGED AND THEN APPEND THEM
        for (const property in sendState) {

            if (property) {
                if (property === "avatar") {
                    //CHECK FOR SAME PROPERTIES IF ARE CHANGED AND THEN APPEND THEM
                    if (this.state.file_avatar) {
                        formData.append(property, this.state.file_avatar)
                    }
                } else if (property === "company_logo") {
                    //CHECK FOR SAME PROPERTIES IF ARE CHANGED AND THEN APPEND THEM
                    if (this.state.file_company_logo) {
                        formData.append(property, this.state.file_company_logo)
                    }
                } else if (property === "timezone") {
                    formData.append(property, this.state.timezone.tz)
                } else if (property === "tz") {
                    formData.append(property, null)
                } else {
                    formData.append(property, sendState[property])
                }
            }

        }
        formData.append('_method', 'PUT')

        this.props.updateProfile(formData)
            .then(() => {
                window.location.reload();
            })
    }

    // handleUpdateLI = () => {
    //     const {classes} = this.props;
    //     if (!this.state.setUpdate) {
    //         return (
    //             <React.Fragment>
    //                 <Typography variant='h6' className='profile__wrap__content-info__desc'>
    //                     <Link className={classes.link}
    //                           href={`https://www.linkedin.com/in/${this.state.linkedin}`}>
    //                         {`https://www.linkedin.com/in/${this.state.linkedin}`}
    //                     </Link>
    //                 </Typography>
    //                 <IconButton
    //                     onClick={() => {
    //                         this.setState({setUpdate: true})
    //                     }}
    //                     aria-label="edit">
    //                     <EditIcon/>
    //                 </IconButton>
    //             </React.Fragment>
    //         )
    //     } else {
    //         return (
    //             <React.Fragment>
    //                 <Typography variant='h6' className='profile__wrap__content-info__desc'>
    //                     <Link className={classes.link}
    //                           href='#'>
    //                         https://www.linkedin.com/in/
    //                     </Link>
    //                 </Typography>
    //                 <TextField
    //                     onChange={this.updateLI}
    //                     value={this.state.linkedin}
    //                     style={{fontSize: '20px'}}
    //                     id="liURL"/>
    //                 <IconButton
    //                     onClick={() => {
    //                         this.setState({setUpdate: false})
    //                     }}
    //                     aria-label="cancel">
    //                     <CheckIcon/>
    //                 </IconButton>
    //             </React.Fragment>
    //         )
    //     }
    // }

    handleUpdateName = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    handleUpdateUserInfo = (e) => {
        this.setState({
            user_info: e.target.value
        })
    }

    handleUpdateTitleAndDepartment = (e) => {
        this.setState({
            title_and_department: e.target.value
        })
    }

    handleUpdateCompanyName = (e) => {
        this.setState({
            company_name: e.target.value
        })
    }

    handleUpdateCompanyAddress = (e) => {
        this.setState({
            company_address: e.target.value
        })
    }

    handleUpdateCompanyLocality = (e) => {
        this.setState({
            company_locality: e.target.value
        })
    }

    handleUpdateCompanyInfo = (e) => {
        this.setState({
            company_info: e.target.value
        })
    }

    handleUpdateCompanyWebsite = (e) => {
        this.setState({
            company_website: e.target.value
        })
    }

    handleUpdateTwitter = (e) => {
        this.setState({
            twitter: e.target.value
        })
    }

    handleUpdateEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    handleUpdateInstagram = (e) => {
        this.setState({
            instagram: e.target.value
        })
    }

    handleUpdateFacebook = (e) => {
        this.setState({
            facebook: e.target.value
        })
    }

    handleUpdateYoutube = (e) => {
        this.setState({
            youtube: e.target.value
        })
    }

    handleUpdateAvatar = (e) => {
        this.setState({
            avatar: e.target.value
        })
    }

    handleUpdateCompanyLogo = (e) => {
        this.setState({
            company_logo: e.target.value
        })
    }

    handleFile = (e, field) => {

        if (e.target.files[0]) {
            if (field === 'avatar') {
                const fileAvatar = e.target.files[0];
                const fileAvatarName = e.target.files[0].name;
                const fileAvatarSize = e.target.files[0].size;

                this.setState({
                    file_avatar: fileAvatar,
                    uploadedFileText: fileAvatarName,
                    uploadedFileSize: fileAvatarSize,
                    isAvatarSizeGrater: parseFloat(fileAvatarSize / (1024 * 1024)).toFixed(2) > this.state.maxImageSize,
                    avatar: URL.createObjectURL(fileAvatar),
                    showRemoveBtn: true
                });
            } else if (field === 'company_logo') {
                const fileCompanyLogo = e.target.files[0];
                const fileCompanyLogoName = e.target.files[0].name;
                const fileCompanyLogoSize = e.target.files[0].size;
                this.setState({
                    file_company_logo: fileCompanyLogo,
                    uploadedFileText: fileCompanyLogoName,
                    isCompanyLogoSizeGrater: parseFloat(fileCompanyLogoSize / (1024 * 1024)).toFixed(2) > this.state.maxImageSize,
                    company_logo: URL.createObjectURL(fileCompanyLogo),
                    showRemoveBtn: true
                });
            }
        } else {
            return null;
        }


    }

    isFielded = () => {
        return Boolean(this.state.timezone && this.state.company_name && this.state.title_and_department && this.state.email && this.state.name)
    }

    a11yProps = (index) => {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }


    tabChange = (event, newValue) => {
        this.setState({ tabValue: newValue })
    };


    IsFormValid = () => {
        return this.state.isAvatarSizeGrater || this.state.isCompanyLogoSizeGrater
    }

    render() {
        const { classes } = this.props;
        return (
            <div className='profile__wrap'>
                <Grid container justify={"center"}>
                    <Grid item md={12} lg={6} className={classes.root}>
                        <AppBar position="static">
                            <Tabs
                                variant="fullWidth"
                                value={this.state.tabValue}
                                onChange={this.tabChange}
                                aria-label="nav tabs example"
                            >
                                <LinkTab label="About me" href="/about-me" {...this.a11yProps(0)} />
                                <LinkTab label="Company" href="/company" {...this.a11yProps(1)} />
                                <LinkTab label="Social Media" href="/social-media" {...this.a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <form noValidate autoComplete="off">
                            <TabPanel value={this.state.tabValue} index={0}>
                                <div className='profile__wrap__content tab-user'>
                                    <Avatar alt={this.state.name} src={this.state.avatar}
                                        className='profile__wrap__content-avatar' />
                                    <input
                                        accept="image/*"
                                        className={classes.inputImageUser}
                                        id='profileImage'
                                        type="file"
                                        required
                                        name='file'
                                        onChange={(e) => this.handleFile(e, 'avatar')}
                                    />
                                    <p style={{ textAlign: "center" }}>
                                        <Typography variant="caption" style={{ color: this.state.isAvatarSizeGrater ? 'red' : 'inherit' }} gutterBottom className={'MuiFormHelperText-root'}>
                                            (The image should not be greater than 10MB.)
                                        </Typography>
                                    </p>
                                    <Fab color="primary" aria-label="add" className={classes.fabButtonImageUser}>
                                        <label htmlFor="profileImage"
                                            className={classes.buttonImageUser}
                                        >
                                            choose image
                                        </label>
                                    </Fab>
                                    <div className='profile__wrap__content-info'>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-name"
                                                    label="Name (required)"
                                                    style={{ margin: '8px 0' }}
                                                    placeholder="Johny Doe"
                                                    value={this.state.name}
                                                    required={true}
                                                    onChange={(e) => {
                                                        this.handleUpdateName(e)
                                                    }}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-company-name"
                                                    label="Title And Department"
                                                    value={this.state.title_and_department}
                                                    onChange={(e) => {
                                                        this.handleUpdateTitleAndDepartment(e)
                                                    }}
                                                    required={true}
                                                    style={{ margin: '8px 0' }}
                                                    placeholder=""
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-company-name"
                                                    label="Company name"
                                                    value={this.state.company_name}
                                                    required={true}
                                                    onChange={(e) => {
                                                        this.handleUpdateCompanyName(e)
                                                    }}
                                                    style={{ margin: '8px 0' }}
                                                    placeholder=""
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-person-description"
                                                    label="Introduce yourself"
                                                    style={{ margin: '8px 0' }}
                                                    rows={4}
                                                    placeholder="Please introduce yourself in a few words."
                                                    value={this.state.user_info}
                                                    onChange={(e) => {
                                                        this.handleUpdateUserInfo(e)
                                                    }}
                                                    fullWidth
                                                    multiline
                                                    variant="outlined"
                                                    margin="normal"
                                                />

                                            </Grid>
                                        </Grid>

                                        <Grid container justify={"center"} space={3} style={{ marginTop: 15 }}>
                                            <Grid item xs={12}>
                                                <div className='profile__wrap__content-info__desc'>
                                                    <Autocomplete
                                                        style={{ margin: '8px 0' }}
                                                        margin="normal"
                                                        id="combo-box-demo"
                                                        options={this.state.tz ? this.state.tz : []}
                                                        getOptionLabel={(option) => option.text ? option.text : ''}
                                                        value={this.state.timezone ? this.state.timezone : this.state.currentTimeZone}
                                                        getOptionSelected={(option, value) => option.text === value.text}
                                                        onChange={(event, value) =>
                                                            this.updateTimeZone(value)
                                                        }
                                                        renderInput={(params) => <TextField {...params}
                                                            label="Choose your TimeZone"
                                                            helperText="You can type your city into this input search field"
                                                            variant="outlined" />}
                                                    />

                                                </div>
                                            </Grid>
                                            <Grid item xs={12} className='d-flex justify-flex-end '>
                                                <Button
                                                    variant="contained"
                                                    className={`${classes.buttonPrimary} mt-3`}
                                                    disabled={this.IsFormValid()}
                                                    onClick={() => {
                                                        this.submitProfileData()
                                                    }}
                                                >
                                                    update profile information
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={1}>
                                <div className='profile__wrap__content tab-company'>
                                    <Avatar alt={this.state.company_name} src={this.state.company_logo}
                                        className='profile__wrap__content-avatar avatar-company' style={{ borderRadius: 'initial' }} />
                                    <input
                                        accept="image/*"
                                        className={classes.inputImageUser}
                                        id='profileImage'
                                        type="file"
                                        required
                                        name='file'
                                        onChange={(e) => this.handleFile(e, 'company_logo')}
                                    />
                                    <p style={{ textAlign: "center" }}>
                                        <Typography variant="caption" style={{ color: this.state.isCompanyLogoSizeGrater ? 'red' : 'inherit' }} gutterBottom className={'MuiFormHelperText-root'}>
                                            (The image should not be greater than 10MB.)
                                        </Typography>
                                    </p>
                                    <Fab color="primary" aria-label="add" className={classes.fabButtonImageUser}>
                                        <label htmlFor="profileImage"
                                            className={classes.buttonImageUser}
                                        >
                                            choose image
                                        </label>
                                    </Fab>

                                    <div className='profile__wrap__content-info'>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-company-address-name"
                                                    label="Company Address"
                                                    value={this.state.company_address}
                                                    onChange={(e) => {
                                                        this.handleUpdateCompanyAddress(e)
                                                    }}
                                                    style={{ margin: '0' }}
                                                    placeholder=""
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-company-address-city-name"
                                                    label="City, State/ Province, Country"

                                                    value={this.state.company_locality}
                                                    onChange={(e) => {
                                                        this.handleUpdateCompanyLocality(e)
                                                    }}
                                                    style={{ margin: '0' }}
                                                    placeholder=""
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-company-describe"
                                                    label="Company describe"
                                                    value={this.state.company_info}
                                                    onChange={(e) => {
                                                        this.handleUpdateCompanyInfo(e)
                                                    }}
                                                    multiline
                                                    row={4}
                                                    style={{ margin: '0' }}
                                                    placeholder="Briefly describe what your company/organization does or service it provides."
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-company-website"
                                                    label="website"
                                                    value={this.state.company_website}
                                                    onChange={(e) => {
                                                        this.handleUpdateCompanyWebsite(e)
                                                    }}
                                                    style={{ margin: '0' }}
                                                    placeholder=""
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='d-flex justify-flex-end'>
                                                <Button
                                                    variant="contained"
                                                    className={classes.buttonPrimary}
                                                    disabled={this.IsFormValid()}
                                                    onClick={() => {
                                                        this.submitProfileData()
                                                    }}
                                                >
                                                    update profile information
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={2}>
                                <div className='profile__wrap__content tab-socials'>
                                    <div className='profile__wrap__content-info'>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-facebook"
                                                    style={{ margin: '0' }}
                                                    placeholder="facebook.com/"
                                                    value={this.state.email}
                                                    onChange={(e) => {
                                                        this.handleUpdateEmail(e)
                                                    }}
                                                    fullWidth
                                                    required={true}
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EmailIcon style={{ color: '#2D9248' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-facebook"
                                                    style={{ margin: '0' }}
                                                    placeholder="facebook.com/"
                                                    value={this.state.facebook}
                                                    onChange={(e) => {
                                                        this.handleUpdateFacebook(e)
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FacebookIcon style={{ color: '#4064AC' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-linkedIn"
                                                    style={{ margin: '0' }}
                                                    placeholder="linkedIn.com/"
                                                    value={this.state.linkedin}
                                                    onChange={(e) => {
                                                        this.updateLI(e)
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LinkedInIcon style={{ color: '#0073AF' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-twitter"
                                                    style={{ margin: '0' }}
                                                    placeholder="twitter.com/"
                                                    value={this.state.twitter}
                                                    onChange={(e) => {
                                                        this.handleUpdateTwitter(e)
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <TwitterIcon style={{ color: '#4064AC' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-instagram"
                                                    style={{ margin: '0' }}
                                                    placeholder="instagram.com/"
                                                    value={this.state.instagram}
                                                    onChange={(e) => {
                                                        this.handleUpdateInstagram(e)
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <InstagramIcon style={{ color: '#F37041' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="profile-youtube"
                                                    style={{ margin: '0' }}
                                                    placeholder="youtube.com/"
                                                    value={this.state.youtube}
                                                    onChange={(e) => {
                                                        this.handleUpdateYoutube(e)
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <YouTubeIcon style={{ color: '#F60002' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} className='d-flex justify-flex-end'>
                                                <Button
                                                    variant="contained"
                                                    className={classes.buttonPrimary}
                                                    disabled={this.IsFormValid()}
                                                    onClick={() => {
                                                        this.submitProfileData()
                                                    }}
                                                >
                                                    update profile information
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </TabPanel>
                        </form>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        tz: state.tz,
        userData: state.user,
        userProfile: state.userProfile
    };
}

const mapDispatchToProps = dispatch => ({
    fetchTimeZones: () => dispatch(fetchTimeZones()),
    updateProfile: data => dispatch(updateProfile(data))
})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ProfilePage)));
