import {
    FLAG_CONVERSATIONS, FLAG_CONVERSATIONS_ERROR, FLAG_USERS, FLAG_USERS_ERROR
} from '../actions/types'
import {topics} from "./TopicsReducer";


let INITIAL_STATE = {
    conversations: null,
    users: null,
    err: null
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FLAG_CONVERSATIONS:
            let allConversationsArr = topics(action.payload.data, 'start_at', 'end_at')
            return {...state, conversations: allConversationsArr}
        case FLAG_CONVERSATIONS_ERROR:
            return {...state, err: action.payload}
        case FLAG_USERS:
            let allUsersArr = topics(action.payload.data, 'start_at', 'end_at')
            return {...state, users: allUsersArr}
        case FLAG_USERS_ERROR:
            return {...state, err: action.payload}
        default:
            return state;
    }
}
