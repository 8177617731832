import {
    JOIN_MEETING, MEETING_UUID
} from '../actions/types'


export default (state = {}, action) => {

    switch (action.type) {
        case JOIN_MEETING:
            return {...state, meeting: action.payload.data}
        case MEETING_UUID:
            return {...state, meetingUUId: action.data}
        default:
            return state;
    }
}
