import {
    FETCH_TAGS
} from '../actions/types'


export default (state = {}, action) => {

    switch (action.type) {
        case FETCH_TAGS:
            return {...state, data: action.payload.data}
        default:
            return state;
    }
}
