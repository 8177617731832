import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {TableHead, withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {conversationsWithFlag, showModal} from "../../actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {DateTime} from "luxon";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(avatar, name, room, date, time, subs, live, flags) {
    return {avatar, name, room, date, time, subs, live, flags};
}

createData.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    room: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    subs: PropTypes.number,
    live: PropTypes.number,
    flags: PropTypes.number
};

const useStyles2 = makeStyles(theme => ({
    table: {
        minWidth: 500,
    },
    deleteButton: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.link.main,

        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.link.main
        }
    },
    importantMark: {
        color: theme.palette.secondary.main,
    },
    numberFlag: {
        color: '#00a2ff',
        display: 'flex',
        width: '24px',
        height: '24px',
        fontSize: '18px',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function TableConversations(props) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [conference, setConference] = React.useState(props.conference);
    const [conversations, setConversations] = React.useState( props.conversations);
    let emptyRows;

    useEffect(() => {
        setConversations(props.conversations)
    }, [props.conversations]);

    useEffect(() => {
        setConference(props.conference)
    }, [props.conference]);

    useEffect(() => {
        if (conversations) {
            emptyRows = rowsPerPage - Math.min(rowsPerPage, conversations.length - page * rowsPerPage);
        } else {
            props.conversationsWithFlag(conference.data.uuid)
        }
    }, [conversations]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function showModal(type, data) {
        props.showModal(type, data)
    }

    return (
        <>
            {
                conversations
                    ?
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{width: '90px'}}>

                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        Host Name
                                    </StyledTableCell>
                                    <StyledTableCell align="left" style={{width: '44%'}}>
                                        Conversation Room
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Date
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Time
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Subs
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Live #
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{width: '50px'}}>
                                        # Flags
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{width: '90px'}}>

                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                        ? conversations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : conversations
                                ).map((row) => (
                                    <TableRow key={row.title}>
                                        <TableCell component="th" scope="row" align="center">
                                            <Avatar alt={row.host.name} src={row.host.avatar}
                                                    style={{marginRight: 'auto', marginLeft: 'auto'}}/> </TableCell>
                                        <TableCell align="left">
                                            {row.host.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.title}
                                        </TableCell>
                                        <TableCell align="center" className={classes.importantMark}>
                                            {DateTime.fromISO(row.start_at).toFormat('DD')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {DateTime.fromISO(row.start_at).toFormat('t') + " - " + DateTime.fromISO(row.end_at).toFormat('t')}
                                        </TableCell>
                                        <TableCell align="center" className={classes.importantMark}>
                                            {row.subscribed}
                                        </TableCell>
                                        <TableCell align="center" className={classes.importantMark}>
                                            {row.live}
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                row.flags.length > 0
                                                    ?
                                                    <IconButton
                                                        aria-label="flags"
                                                        onClick={() => {
                                                            showModal('modal-show-flags', {...row})
                                                        }}
                                                    >
                                                        <span className={classes.numberFlag}>
                                                            {row.flags.length}
                                                        </span>
                                                    </IconButton>
                                                    :
                                                    <IconButton
                                                        aria-label="flags"
                                                        disabled={true}
                                                    >
                                                        <span className={classes.numberFlag}>
                                                            {row.flags.length}
                                                        </span>
                                                    </IconButton>
                                            }

                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                aria-label="delete"
                                                className={classes.deleteButton}
                                                onClick={() => {
                                                    showModal('modal-delete-topic', {
                                                        topicTitle: row.title,
                                                        topicId: row.id,
                                                        conferenceUUID: conference.data.uuid,
                                                        deleteObject: 'conversation'
                                                    })
                                                }}
                                            >
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {
                                    emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                        colSpan={9}
                                        count={conversations.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    :
                    <Typography>
                        Loading....
                    </Typography>
            }
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        conference: state.conferences,
        conversations: state.flags.conversations
    };
}

const mapDispatchToProps = dispatch => ({
    showModal: (type, data) => dispatch(showModal(type, data)),
    conversationsWithFlag: (conferenceUuid) => dispatch(conversationsWithFlag(conferenceUuid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TableConversations);
