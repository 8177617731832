import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import {
    Card,
    CardActions,
    CardContent,
    Collapse, Grid, Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import UserCard from "../userCards/UserCard";
import {DateTime} from "luxon";

const styles = theme => ({
    root: {
        maxWidth: '100%',
        backgroundColor: theme.palette.primary.main
    },

    control: {
        padding: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
});


class TopicsSectionSimple extends Component {

    state = {
        expanded: true,
        tabIndex: 0,
        tabValue: 0,
        topicsSection: null,
        topicsSectionHeight: 0,
    }

    //divElement is for getting height of userCard and set it to the parent component
    //and when dynamically set it on resize
    divElement = React.createRef();

    componentDidMount() {
        // this.props.topicsSection are topics which comes from parent
        if (this.props?.topicsSection) {
            this.setState({topicsSection: this.props.topicsSection})
        }
    }

    componentDidUpdate() {
        // this.props.topicsSection are topics which comes from parent
        if (this.props?.topicsSection && this.props.topicsSection !== this.state.topicsSection) {
            this.setState({topicsSection: this.props.topicsSection})
        }
    }


    componentWillUnmount() {

    }

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
    };


    orderUserCards = (a, b) => {
        if (a && b) {
            const titleA = a.props.children.props.topic.topicTitle.toUpperCase(); // ignore upper and lowercase
            const titleB = b.props.children.props.topic.topicTitle.toUpperCase(); // ignore upper and lowercase

            // compare dates ad times by timestamps, but the time stamps are incorrect so
            // you have to compare the years, months, days, hours and minutes
            const startDateA = DateTime.fromISO(a.props.children.props.topic.startDate).ts;
            const startDateB = DateTime.fromISO(b.props.children.props.topic.startDate).ts;
            const endDateA = DateTime.fromISO(a.props.children.props.topic.endDate).ts;
            const endDateB = DateTime.fromISO(b.props.children.props.topic.endDate).ts;


            if (!isNaN(Number(startDateA)) && !isNaN(Number(startDateB))) {
                if (Number(startDateA) > Number(startDateB)) {
                    return 1;
                } else if (Number(startDateA) < Number(startDateB)) {
                    return -1;
                } else if (Number(endDateA) > Number(endDateB)) {
                    return 1;
                } else if (Number(endDateA) < Number(endDateB)) {
                    return -1;
                } else {
                    return 0;
                }

            } else {
                if (titleA > titleB) {
                    return 1;
                } else if (titleA < titleB) {
                    return -1;
                } else {
                    return 0;
                }
            }

        } else {
            return null
        }

    }

    renderTopics = (topics) => {
        if (topics.length !== 0) {
            return (
                topics.map((topic, index) => {
                    return (
                        <Grid key={index} item md={6}

                              ref={this.divElement}
                        >
                            <UserCard
                                topic={topic}/>
                        </Grid>
                    )
                })
                    // .sort(this.orderUserCards)
            )
        } else {
            return (
                <Grid item md={6}>
                    {/*IF THERE ISN'T ANY EVENTS*/}
                    <Paper variant="outlined" className='p-16'>
                        <Typography paragraph>
                            No conversations at this time.
                        </Typography>
                        <Typography paragraph>
                            Create virtual group conversations and invite friends.
                        </Typography>
                    </Paper>
                    {/*IF THERE ISN'T ANY EVENTS*/}
                </Grid>
            )
        }
    };

    renderTopic = (topics) =>{
        if (topics.length !== 0) {
            return (
                topics.map((topic, index) => {
                    return (
                        <Grid key={index} item md={6}
                              ref={this.divElement}
                        >
                            <UserCard
                                topic={topic}/>
                        </Grid>
                    )
                })
            )
        } else {
            return (
                <Grid item md={6}>
                    {/*IF THERE ISN'T ANY EVENTS*/}
                    <Paper variant="outlined" className='p-16'>
                        <Typography paragraph>
                            No conversations at this time.
                        </Typography>
                        <Typography paragraph>
                            Create virtual group conversations and invite friends.
                        </Typography>
                    </Paper>
                    {/*IF THERE ISN'T ANY EVENTS*/}
                </Grid>
            )
        }
    }

    renderTopicSectionContent = () => {
        const {classes} = this.props;
        return (
            <Card className={classes.root}>
                <div
                    className='card-header__wrap'
                    onClick={this.handleExpandClick}
                >
                    <CardContent>
                        <Typography
                            variant='h5'
                            color='secondary'
                        >
                            {this.state.topicsSection.title}
                        </Typography>
                    </CardContent>

                    <CardActions disableSpacing>

                        <IconButton
                            color='secondary'
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            aria-expanded={this.state.expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </CardActions>
                </div>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>

                        {/*PANEL*/}
                        {
                            this.state.topicsSection.tabs
                                ?
                                this.state.topicsSection.tabs.map((tab, index) => {
                                    return (

                                        <Grid container key={index} spacing={2}>
                                            {this.renderTopics(tab.topics)}
                                        </Grid>
                                    )
                                })
                                :
                                        <Grid container spacing={2}>
                                            {this.renderTopic(this.state.topicsSection)}
                                        </Grid>
                        }
                        {/*!PANEL*/}
                    </CardContent>
                </Collapse>
            </Card>
        )
    }

    render() {
        return (
            <div className='collapse-section__wrap'>
                {
                    this.state.topicsSection
                        ?
                        this.renderTopicSectionContent()
                        :
                        <Typography>
                            Loading ...
                        </Typography>
                }
            </div>
        );
    }

}

export default withStyles(styles, {withTheme: true})(TopicsSectionSimple);
