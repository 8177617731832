import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import {fetchTags, showModal} from "../../actions";
import {connect} from "react-redux";
import {Autocomplete} from '@material-ui/lab'
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: 0
        },
        '& input': {
            padding: '1rem 1.5rem !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        },
        '& input:focus:hover + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        }
    },
});


class AutocompleteTagField extends Component {

    // ALLtAGS ARE TAGS THAT COMES FROM BACKEND
    //TAGS ARE TAGS THAT DISPATCH TO PARENT - MODAL SET TOPIC AND CREATE TOPICS WITH THEM
    state = {
        addTopic: '',
        event_id: null,
        user: null,
        allTags: this.props.tags,
        tags: null,
        defaultTags: this.props.defaultTags
    }

    componentDidMount() {
        this.fetchTags()
    }

    componentDidUpdate() {
        this.fetchTags()
    }

    fetchTags = () => {
        if (this.props?.tags && this.props.tags !== this.state.allTags) {
            this.setState({allTags: this.props.tags})
        }
    }

    addTopic = (e) => {
        this.setState({addTopic: e.target.value})
    }

    handleKeyDown = (event) => {
        switch (event.key) {
            case ",": {
                event.preventDefault();
                event.stopPropagation();

                const valueLength = event.target.value.length;
                const value = event.target.value;

                if (valueLength > 0) {
                    // setValue([...value, event.target.value]);
                    this.setState(prevState => ({
                        tags: [...(prevState.tags || []), value]
                    }));
                }
                break;
            }
        }
    };

    dispatchTagsToParent = (tags) => {
        this.props.dispatchedTags(tags)
    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Autocomplete
                    multiple
                    freeSolo
                    id="tags-outlined"
                    defaultValue={this.state.defaultTags}
                    className={classes.textField}
                    options={this.state.allTags ? this.state.allTags : ['Loading...']}
                    getOptionLabel={(option) => option.name || option}
                    onChange={(event, newValue) => {
                        this.setState({
                            tags: newValue
                        }, () => {
                            this.dispatchTagsToParent(this.state.tags)
                        })
                    }}
                    filterSelectedOptions
                    renderInput={params => {
                        params.inputProps.onKeyDown = this.handleKeyDown;
                        return (
                            <TextField
                                {...params}
                                variant="outlined"
                                // label="filterSelectedOptions"
                                placeholder="Topic Tags ..."
                            />

                        )
                    }}
                />
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        tags: state.topics.tags
    };
}

const mapDispatchToProps = dispatch => ({
    showModal: (type, data) => dispatch(showModal(type, data))
})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(AutocompleteTagField));

