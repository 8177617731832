export const DRAWER_OPEN= 'DRAWER_OPEN';
export const DRAWER_CLOSE = 'DRAWER_CLOSE';
export const FETCH_LIVE_TOPICS = 'FETCH_LIVE_TOPICS';
export const FETCH_UPCOMING_TOPICS = 'FETCH_UPCOMING_TOPICS';
export const FETCH_SET_TOPIC_MODAL = 'FETCH_SET_TOPIC_MODAL';
export const CLOSE_SET_TOPIC_MODAL = 'CLOSE_SET_TOPIC_MODAL';
export const FETCH_NOT_FOUND_MODAL = 'FETCH_NOT_FOUND_MODAL';
export const CLOSE_NOT_FOUND_MODAL = 'CLOSE_NOT_FOUND_MODAL';
export const FETCH_MY_TOPICS = 'FETCH_MY_TOPICS';
export const FETCH_LOGIN_MODAL = 'FETCH_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const FETCH_TIME_ZONES = 'FETCH_TIME_ZONES';
export const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS';
export const SEARCH_CONNECTIONS = 'SEARCH_CONNECTIONS';
export const FETCH_TOPIC_FEEDBACK_MODAL = 'FETCH_TOPIC_FEEDBACK_MODAL';
export const CLOSE_TOPIC_FEEDBACK_MODAL = 'CLOSE_TOPIC_FEEDBACK_MODAL';
export const FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL = 'FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL';
export const CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL = 'CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const FETCH_CREATE_TOPIC_ERROR = 'FETCH_CREATE_TOPIC_ERROR';
export const FETCH_TOPICS = 'FETCH_TOPICS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_BRANDING = 'UPDATE_PROFILE';
export const UPDATE_BRANDING_ERROR = 'UPDATE_PROFILE_ERROR';
export const FETCH_TAGS = 'FETCH_TAGS';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const FETCH_TOPIC_ERROR = 'FETCH_TOPIC_ERROR';
export const RETURN_TOPICS_BY_TAG = 'RETURN_TOPICS_BY_TAG';
export const SUBSCRIBE_TOPIC = 'SUBSCRIBE_TOPIC';
export const JOIN_MEETING = 'JOIN_MEETING';
export const FETCH_CONNECTIONS_ERROR = 'FETCH_CONNECTIONS_ERROR';
export const FETCH_UPCOMING_MEETINGS = 'FETCH_UPCOMING_MEETINGS';
export const FETCH_UPCOMING_MEETINGS_ERROR = 'FETCH_UPCOMING_MEETINGS_ERROR';
export const FETCH_PAST_MEETINGS = 'FETCH_PAST_MEETINGS';
export const FETCH_PAST_MEETINGS_ERROR = 'FETCH_PAST_MEETINGS_ERROR';
export const MEETING_UUID = 'MEETING_UUID';
export const NOTIFY_SUBSCRIBERS = 'NOTIFY_SUBSCRIBERS';
export const NOTIFY_SUBSCRIBERS_ERROR = 'NOTIFY_SUBSCRIBERS_ERROR';
export const FLAG_TOPIC = 'FLAG_TOPIC';
export const FLAG_TOPIC_ERROR = 'FLAG_TOPIC_ERROR';
export const THEME = 'THEME';
export const MODAL_LINAME = 'MODAL_LINAME';
export const CLOSE_MODAL_LINAME = 'CLOSE_MODAL_LINAME';
export const MODAL_EMAIL_SUBSCRIBERS = 'MODAL_EMAIL_SUBSCRIBERS';
export const CLOSE_MODAL_EMAIL_SUBSCRIBERS = 'CLOSE_MODAL_EMAIL_SUBSCRIBERS';
export const MODAL_DELETE_TOPIC = 'MODAL_DELETE_TOPIC';
export const CLOSE_MODAL_DELETE_TOPIC = 'CLOSE_MODAL_DELETE_TOPIC';
export const MODAL_SHOW_FLAGS = 'MODAL_SHOW_FLAGS';
export const CLOSE_MODAL_SHOW_FLAGS = 'CLOSE_MODAL_SHOW_FLAGS';
export const FETCH_CONFERENCES = 'FETCH_CONFERENCES';
export const FETCH_CONFERENCES_ERROR = 'FETCH_CONFERENCES_ERROR';
export const FLAG_CONVERSATIONS = 'FLAG_CONVERSATIONS';
export const FLAG_CONVERSATIONS_ERROR = 'FLAG_CONVERSATIONS_ERROR';
export const FETCH_TOPICS_BY_TAG = 'FETCH_TOPICS_BY_TAG';
export const FETCH_TOPICS_BY_TAG_ERROR = 'FETCH_TOPICS_BY_TAG_ERROR';
export const FLAG_USERS = 'FLAG_USERS';
export const FLAG_USERS_ERROR = 'FLAG_USERS_ERROR';
export const USER_BLOCK = 'USER_BLOCK';
export const USER_BLOCK_ERROR = 'USER_BLOCK_ERROR';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const DELETE_TOPIC_ERROR = 'DELETE_TOPIC_ERROR';
