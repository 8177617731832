import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';

import {connect} from "react-redux";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import pusher from "pusher-js"
import axios from "axios";
import Echo from "laravel-echo";
import {Divider, InputBase, Paper, Popover, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {fade, withStyles} from "@material-ui/core/styles";
import {joinMeeting} from "../../actions";
import {withSanctum} from "react-sanctum";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import {validateURL} from "../../utilities/methods";

const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        width: '100%',
        display: 'flex',
        alignItems: "center"
    },
    input: {
        width: '100%',
        padding: theme.spacing(1, 1, 1, 2),
    },
    divider: {
        height: 28,
        margin: '7px 0',
    },
    listItemContent: {
        display: 'flex',
        flexDirection: 'row'
    },
    noMaxWidth: {
        maxWidth: 'none',
        backgroundColor: 'rgba(255,255,255,1) !important',
        color: '#616161 !important'
    },
    attendeesList: {
        height: '63vh',
        overflow: 'scroll'
    },
    attendeeLiveFieldsWrap: {
        padding: '0 0.7rem 1rem 0.7rem',
        overflowY: 'scroll',
        width: '25rem',
        maxHeight: '37.5rem',
    },
    attendeeLiveField: {
        marginTop: '1rem',
        textAlign: 'center'
    },
    attendeeLiveFieldAnchor: {
        color: '#616161',
        textDecoration: 'underline'
    },
    attendeeLiveFieldCompanyLogo: {
        margin: '2rem auto',
        padding: '2rem',
        backgroundColor: '#757575',
        color: theme.palette.link.main,
        textAlign: 'center'
    }
})

class ListLiveUsers extends Component {

    state = {
        areAttendeesHere: false,
        attendees: [],
        attendeeLive: [],
        eventId: null,
        search: '',
        sortAttendees: false,
        selectedSortingButton: false,
        eventName: '',
        user: null,
        meeting: null,
        anchorEl: null,
        open: false,
        popoverId: undefined,
        attendeesLength: null
    }

    myRef = React.createRef();

    handlePopoverOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: true
        })
    }

    handlePopoverClose = () => {
        this.setState({
            open: false
        })
    }

    fetchAndSetLiveAttendees = () => {
        this.props.joinMeeting({topic_id: this.props.topicId})
            .then(() => {
                if (this.props.meeting && this.props.userData) {
                    this.setState({
                        meeting: this.props.meeting,
                        user: this.props.userData,
                        attendees: this.props.meeting.user_meetings,
                        attendeesLength: this.props.meeting.user_meetings.length,
                        areAttendeesHere: true
                    }, () => {
                        // join trough Meeting.js and here we re-join the user
                        // re-join to the meeting.
                        // In this way the first user can see its avatar, name and li into live-users list
                        // re-join prerender the user list
                        //THIS DO NOT WORKS ON LOCALHOST YOU HAVE TO TEST IT ON STAGING
                        this.props.joinMeeting({topic_id: this.state.meeting.id})
                    })
                    window.echo = new Echo({
                        broadcaster: "pusher",
                        key: 'c21abc863830ea8c1fad',
                        cluster: "eu",
                        forceTLS: true,
                        encrypted: false,
                        authorizer: (channel, options) => {
                            return {
                                authorize: (socketId, callback) => {
                                    axios.defaults.withCredentials = true;
                                    axios.post(process.env.REACT_APP_API_URL + '/broadcasting/auth', {
                                        socket_id: socketId,
                                        channel_name: `private-topic.${this.props.meeting.id}`,
                                    })
                                        .then(response => {
                                            callback(false, response.data);
                                        })
                                        .catch(error => {
                                            callback(true, error);
                                        });
                                }
                            };
                        },
                    });


                    window.echo.private(`topic.${this.props.meeting.id}`)
                        .listen('.users.live', function (e) {
                            this.setState({
                                attendeesLength: e.topic.users_meetings.length
                            })

                        }.bind(this));
                }
            })
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideOfAttendeePopOverCard);
        this.fetchAndSetLiveAttendees()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.meeting && this.props.meeting?.user_meetings && this.props.meeting.user_meetings.length !== this.state.attendeesLength) {

            this.props.joinMeeting({topic_id: this.state.meeting.id})
                .then(() => {
                    // console.log('това фечваот тук 3 pyti');
                    this.setState({
                        attendees: this.props.meeting.user_meetings,
                        attendeesLength: this.props.meeting.user_meetings.length
                    })
                })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideOfAttendeePopOverCard);
    }

    handleClickOutsideOfAttendeePopOverCard = (e) => {
        if (this.state.open && !this.myRef.current.contains(e.target)) {
            this.handlePopoverClose()
        }
    }

    updateSearch = (data) => {
        this.setState({search: data.target.value})
    }

    sortAttendeesList = () => {
        this.setState({
            sortAttendees: !this.state.sortAttendees,
            selectedSortingButton: !this.state.selectedSortingButton
        })
    }

    dynamicSort = (attendee) => {

        let sortOrder = 1;

        if (attendee[0] === "-") {
            sortOrder = -1;
            attendee = attendee.substr(1);
        }

        if (this.state.sortAttendees) {
            return function (a, b) {
                if (sortOrder == -1) {
                    return a[attendee].localeCompare(b[attendee]);
                } else {
                    return b[attendee].localeCompare(a[attendee]);
                }
            }
        } else {
            return function (a, b) {
                if (sortOrder == -1) {
                    return b[attendee].localeCompare(a[attendee]);
                } else {
                    return a[attendee].localeCompare(b[attendee]);
                }
            }
        }
    }

    filteredAttendeeList = () => {
        return (
            this.state.attendees.filter((attendee) => {
                    return attendee.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                }
            )
        )
    }

    renderLimitedText = (text = '') => {
        if (text && text.length > 160) {
            return text.slice(0, 160) + '...'
        } else {
            return text
        }
    }

    returnImage = (image) => {
        var img = document.createElement('img');
        img.src = image;
        img.onload = (e) => {
            return true;
        }
        img.onerror = (e) => {
            return false;
        }
    }

    popover = (attendee) => {
        const {classes} = this.props;
        return (
            <div
                className={classes.attendeeLiveFieldsWrap}
            >
                <Typography variant="h5" className={classes.attendeeLiveField}>
                    {attendee.name}
                </Typography>
                <Typography variant='h6' className={classes.attendeeLiveField}>
                    {attendee.title_and_department}
                </Typography>
                <Typography variant='h6' className={classes.attendeeLiveField}>
                    {attendee.company_name}
                </Typography>
                <div>
                    {
                        attendee.company_logo ?
                            <img src={attendee.company_logo} alt={attendee.company_name}
                                 className={'avatar-squared d-block h-auto mt-16 mr-auto ml-auto'} />:
                            <div className={classes.attendeeLiveFieldCompanyLogo}>
                                <Typography variant={"h4"}>
                                    Company Logo
                                </Typography>
                            </div>
                    }
                </div>
                <Typography variant='body1'
                            className={classes.attendeeLiveField}>
                    Address <br/>
                    {attendee.company_address}
                </Typography>
                <Typography variant='body1' className={classes.attendeeLiveField}>
                    City <br/>
                    {attendee.company_locality}
                </Typography>
                <Typography variant='body1' className={classes.attendeeLiveField}>
                    <a
                        href={validateURL(attendee.company_website)}
                        target='_blank'
                        className={classes.attendeeLiveFieldAnchor}>
                        {attendee.company_website}
                    </a>
                </Typography>
                <Typography variant="h6"
                            style={{marginTop: '1.5rem'}}
                            className={`text-left`}>
                    Company description:
                </Typography>
                <Typography variant='body1' style={{marginTop: '0.30rem'}}>
                    {/*{this.renderLimitedText(attendee.company_info)}*/}
                    {attendee.company_info}
                </Typography>
                <Typography variant="h6"
                            style={{marginTop: '1.5rem'}}
                            className={`text-left`}>
                    About me:
                </Typography>
                <Typography variant='body1' style={{marginTop: '0.30rem'}}>
                    {/*{this.renderLimitedText(attendee.user_info)}*/}
                    {attendee.user_info}
                </Typography>
            </div>
        )
    }

    render() {
        const {classes} = this.props;
        return (
            <div className='OrganizerAttendees'>
                <div className='flex align-items__center justify-space-between'>
                    <div className='org-attendee__search'>
                        <Paper className={classes.search}>
                            <InputBase
                                className={classes.input}
                                id="org-search-attendee"
                                size="small"
                                label="Search Attendee"
                                value={this.state.search}
                                onChange={(data) => {
                                    this.updateSearch(data)
                                }}/>
                            <Divider
                                className={classes.divider}
                                orientation="vertical"/>
                            <IconButton
                                className='sort-alpha__attendees-list'
                                aria-label="sort attendee list"
                                value={SortByAlphaIcon}
                                selected={this.state.selectedSortingButton}
                                onClick={() => {
                                    this.sortAttendeesList()
                                }}
                            >
                                <SortByAlphaIcon/>
                            </IconButton>
                        </Paper>
                    </div>
                </div>

                <List className={classes.attendeesList}>
                    {
                        this.state.areAttendeesHere
                            ?
                            this.state.attendees.length !== 0
                                ?
                                (this.filteredAttendeeList().sort(this.dynamicSort('name'))).map((row) => (
                                    <div key={row.id}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Tooltip
                                                    title={this.popover(row)}
                                                    interactive
                                                    placement="left-end"
                                                    classes={{tooltip: classes.noMaxWidth}}
                                                    className={classes.listLiveUserCard}
                                                >
                                                    <Avatar src={row.avatar} alt={row.name}/>
                                                </Tooltip>
                                            </ListItemAvatar>
                                            <ListItemText color='secondary'>
                                                <Typography className='list-attendee__title live' variant='h6'>
                                                    {row.name}
                                                </Typography>


                                                <Typography className={`${classes.listItemContent} list-attendee__text`}
                                                            variant='body2'>
                                                    <a href={`${row.facebook ?? '/'}`}
                                                       target='_blank'
                                                       style={{display: row.facebook ? 'block' : 'none'}}
                                                       className={`list-attendee__LI-btn ${row.facebook ? '' : 'disabled-btn'}`}>
                                                        <FacebookIcon style={{color: '#4064AC'}}/>
                                                    </a>
                                                    <a href={`${row.linkedin ?? '/'}`}
                                                       target='_blank'
                                                       style={{display: row.linkedin ? 'block' : 'none'}}
                                                       className={`list-attendee__LI-btn ${row.linkedin ? '' : 'disabled-btn'}`}>
                                                        <LinkedInIcon style={{color: '#0073AF'}}/>
                                                    </a>
                                                    <a href={`${row.twitter ?? '/'}`}
                                                       target='_blank'
                                                       style={{display: row.twitter ? 'block' : 'none'}}
                                                       className={`list-attendee__LI-btn ${row.twitter ? '' : 'disabled-btn'}`}>
                                                        <TwitterIcon style={{color: '#4064AC'}}/>
                                                    </a>
                                                    <a href={`${row.instagram ?? '/'}`}
                                                       target='_blank'
                                                       style={{display: row.instagram ? 'block' : 'none'}}
                                                       className={`list-attendee__LI-btn ${row.instagram ? '' : 'disabled-btn'}`}>
                                                        <InstagramIcon style={{color: '#F37041'}}/>
                                                    </a>
                                                    <a href={`${row.youtube ?? '/'}`}
                                                       target='_blank'
                                                       style={{display: row.youtube ? 'block' : 'none'}}
                                                       className={`list-attendee__LI-btn ${row.youtube ? '' : 'disabled-btn'}`}>
                                                        <YouTubeIcon style={{color: '#F60002'}}/>
                                                    </a>

                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </div>
                                ))
                                :
                                <Typography variant='h5' style={{color: '#fafafa'}}>
                                    There are no attendees for this session yet.
                                </Typography>

                            :
                            <Typography variant='h5' style={{color: '#fafafa'}}>
                                {this.state.areAttendeesHere}
                                Waiting for attendees ...
                            </Typography>
                    }
                </List>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // Object.values will returns array of object
        meeting: state.meeting.meeting,
        userData: state.user
    }
}

const mapDispatchToProps = dispatch => (
    {
        joinMeeting: (topic_id) => dispatch(joinMeeting(topic_id)),
    }
)

export default withSanctum(withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListLiveUsers)));


